.plannings-list {
  &._filter-side,
  &._filter-side-alt {
    .plannings-list {

    }
  }

  &._filter-side {
    .plannings-list {

    }
  }

  &._filter-side-alt {
    .plannings-list {

    }
  }
}

@include respond-up('large') {
  .plannings-list {
    &._filter-side,
    &._filter-side-alt {
      .plannings-list {
        &__picture {
          height: calc((var(--grid-column2) - 60px) * 157 / 155);
          width: calc(var(--grid-column2) - 60px);
        }

        &__layout-price-wrap {
          flex: 0 0 calc(var(--grid-column3) - 40px);
        }

        &__layout-about-wrap {
          flex: 0 0 var(--grid-column3);
        }

        &__image-wrap {
          flex: 0 0 calc(var(--grid-column3) - 60px);
        }
      }
    }

    &._filter-side {
      padding-left: var(--grid-gap);

      .plannings-list {
        &__list-wrapper {
          margin-top: 30px;
        }
      }
    }

    &._filter-side-alt {
      padding-left: calc(var(--grid-gap) + 10px);

      .plannings-list {
        &__list-wrapper {
          margin-top: 20px;
        }

        &__item {

        }

        &__item-link {
          padding: 30px 40px 30px 20px;
        }

        &__favorites {
          bottom: 30px;
          right: 40px;
        }

        &__next-wrap {
          margin-top: 60px;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .plannings-list {
    &._filter-side,
    &._filter-side-alt {
      border-left: 1px solid var(--color-grey-02);

      .plannings-list {
        &__layout-about-wrap {
          row-gap: 20px;
        }

        &__info-value {
          margin-left: var(--grid-gap);
        }
      }
    }

    &._filter-side {
      .plannings-list {
        &__list {
          row-gap: 10px;
        }

        &__item {
          transition: box-shadow var(--default-timing) var(--default-bezier);

          &:hover {
            box-shadow: 0 1px 25px rgba(0, 0, 0, 0.13);
          }
        }

        &__item-link {
          padding: 20px 40px 20px 30px;
        }

        &__favorites {
          bottom: 30px;
          right: 40px;
        }

        &__next-wrap {
          margin-top: 40px;
        }
      }
    }

    &._filter-side-alt {
      .plannings-list {
        &__item {
          transition: background var(--default-timing) var(--default-bezier);

          &:first-child {
            .plannings-list {
              &__item-link {
                border-top: 1px solid var(--color-grey-02);
              }
            }
          }

          &:hover {
            background: var(--color-grey-01);
          }
        }

        &__item-link {
          border-bottom: 1px solid var(--color-grey-02);
        }
      }
    }
  }
}

@include respond('s-large') {
  .plannings-list {
    &._filter-side,
    &._filter-side-alt {
      padding-left: calc(var(--grid-gap) + 10px);

      .plannings-list {
        &__item {
          //border-radius: 20px;
        }

        &__picture {
          height: calc((var(--grid-column2) - 20px) * 157 / 155);
          width: calc(var(--grid-column2) - 20px);
        }

        &__layout-price-wrap {
          flex: 0 0 calc(var(--grid-column3) - 40px);
        }

        &__layout-about-wrap {
          flex: 0 0 var(--grid-column3);
        }

        &__image-wrap {
          flex: 0 0 calc(var(--grid-gap) + var(--grid-column2));
        }
      }
    }

    &._filter-side {
      .plannings-list {
        &__list-wrapper {
          margin-top: 20px;
        }
      }
    }

    &._filter-side-alt {
      padding-left: var(--grid-gap);

      .plannings-list {
        &__list-wrapper {
          margin-top: 16px;
        }

        &__item {
          //border-radius: 20px;
        }

        &__item-link {
          padding: 30px 40px 30px 30px;
        }

        &__favorites {
          bottom: 40px;
          right: 40px;
        }

        &__next-wrap {
          margin-top: 50px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .plannings-list {
    &._filter-side,
    &._filter-side-alt {
      .plannings-list {
        &__picture {
          display: flex;
        }

        &__image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &__image-wrap {
          display: flex;
        }

        &__list {
          display: flex;
          flex-direction: column;
        }

        &__item-link {
          display: flex;
          justify-content: space-between;
        }

        &__item-top-wrap {
          display: flex;
        }

        &__layout-price-wrap,
        &__layout-about-wrap,
        &__layout-info-wrap {
          display: flex;
          flex-direction: column;
        }

        &__layout-price-wrap {
          align-items: end;
        }

        &__item-link,
        &__item-top-wrap {
          column-gap: var(--grid-gap);
        }

        &__info {
          display: flex;
        }

        &__info-name {
          flex: 0 0 var(--grid-column);
        }
      }
    }

    &._filter-side {
      .plannings-list {
        &__item {
          border-radius: 20px;
          overflow: hidden;
          box-shadow: 0 2px 25px rgba(0, 0, 0, 0.07);
        }
      }
    }

    &._filter-side-alt {
      .plannings-list {
        &__list {

        }
      }
    }
  }
}

@include respond('medium') {
  .plannings-list {
    &._filter-side,
    &._filter-side-alt {
      .plannings-list {
        &__list-wrapper {
          margin-top: 20px;
        }

        &__favorites {
          bottom: 30px;
          right: 30px;
        }

        &__picture {
          height: calc((var(--grid-column) + var(--grid-gap) + 6px) * 126 / 124);
          width: calc(var(--grid-column) + var(--grid-gap) + 6px);
        }

        &__layout-price-wrap {
          flex: 0 0 calc(var(--grid-column2) - 30px);
        }

        &__layout-about-wrap {
          flex: 0 0 var(--grid-column2);
        }

        &__image-wrap {
          flex: 0 0 calc(var(--grid-column2) - 20px);
        }

        &__item-link {
          padding: 20px 30px 20px 20px;
        }

        &__info-value {
          margin-left: 10px;
        }

        &__layout-info-wrap {
          margin-top: 10px;
        }
      }
    }

    &._filter-side {
      .plannings-list {
        &__list {
          row-gap: 8px;
        }
      }
    }

    &._filter-side-alt {
      .plannings-list {
        &__item {
          &:before,
          &:after {
            content: '';
            position: absolute;
            left: calc(0px - var(--grid-spacer));
            width: 100vw;
            height: 1px;
            background: var(--color-grey-02);
          }

          &:before {
            top: -1px;
          }

          &:after {
            bottom: 0;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .plannings-list {
    &._filter-side,
    &._filter-side-alt {
      .plannings-list {
        &__next-wrap {
          margin-top: 30px;
        }
      }
    }

    &._filter-side {
      .plannings-list {

      }
    }

    &._filter-side-alt {
      .plannings-list {

      }
    }
  }
}

@include respond-down('small') {
  .plannings-list {
    &._filter-side,
    &._filter-side-alt {
      .plannings-list {

      }
    }

    &._filter-side {
      .plannings-list {

      }
    }

    &._filter-side-alt {
      .plannings-list {

      }
    }
  }
}