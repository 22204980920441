.header-choose-apart-link {
  display: flex;
  align-items: center;
  height: 100%;

  &__icon {
    display: flex;

    svg {
      path {
        fill: var(--color-main-01);
        transition: fill var(--default-timing) var(--default-bezier);
      }
    }
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    margin-left: 16px;
    color: var(--color-main-01);
    transition: color .2s var(--default-bezier);
  }

  &:hover {
    .header-choose-apart-link {
      &__title {
        color: var(--color-main-01-50);
      }

      &__icon {
        svg {
          path {
            fill: var(--color-main-01-50);
          }
        }
      }
    }
  }
}