@media print {
  .header-print {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    padding: 10mm 10mm 5.5mm;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-bottom: .2mm solid var(--color-grey-02);
    flex-wrap: nowrap;
    flex-direction: row;

    &__picture {
      display: flex;
      width: auto;
      height: 100%;
      align-items: flex-start;
      justify-content: center;
      margin: 0;
      padding: 0;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &__header-logo {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1 0 30mm;
      height: 9mm;
    }

    &__contacts {
      display: flex;
      align-items: center;
      flex: 0 1 auto;
    }

    &__contact-icon {
      width: 4.2mm;
      height: 4.2mm;
      margin-left: 12mm;
      display: flex;

      svg {
        height: 100%;
        width: 100%;

        path {
          stroke: var(--color-main-01);
        }
      }
    }

    &__contact {
      margin-left: 2mm;
      font-family: var(--font);
      font-style: normal;
      font-weight: 500;
      color: black;
    }
  }
}