@media print {
  .layout-info-list-print {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 10.5mm;
    flex-direction: column;

    &__item {
      padding: 2.5mm 0;
      border-bottom: .2mm solid var(--color-grey-02);
      display: flex;
      flex-direction: row;
    }

    &__key,
    &__value {
      flex: 0 0 50%;
    }
  }
}