.error-page {
  height: var(--hero-height);
  overflow: hidden;
  position: relative;

  &__picture-container {
    display: flex;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      z-index: 50;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #0D0D15 0%, rgba(10, 11, 19, 0) 100%);
    }
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 60;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &__title {
    color: var(--color-add-04);
  }

  &__description {
    color: var(--color-add-04);
    width: var(--grid-column4);
    margin-top: 10px;
    text-align: center;
  }

  &__button {
    margin-top: 60px;
  }

  &__icon {
    display: flex;
  }

  &.hero_four,
  &.hero_five {
    .error-page {
      &__picture-container {
        will-change: transform;
        
        &::before {
          background: linear-gradient(180deg, rgba(13, 13, 21, 0.8) 0%, rgba(10, 11, 19, 0) 100%);
        }

        &::after {
          content: '';
          position: absolute;
          z-index: 45;
          width: 100%;
          height: 100%;
          background-color: var(--color-main-01);
          opacity: 0.6;
        }
      }

      &__image {
        filter: sepia(1);
      }
    }
  }
}

@include respond-up('large') {
  .error-page {
    &__icon {
      svg {
        width: 320px;
        height: 133px;
      }
    }
  }
}

@include respond('s-large') {
  .error-page {
    &__icon {
      svg {
        width: 289px;
        height: 120px;
      }
    }
  }
}

@include respond-up('medium') {
  .error-page {
    &__title {
      margin-top: 40px;
    }
  }
}

@include respond('medium') {
  .error-page {
    &__button {
      margin-top: 80px;
    }

    &__icon {
      svg {
        width: 241px;
        height: 100px;
      }
    }
  }
}

@include respond-down('small') {
  .error-page {
    &__title {
      margin-top: 40px;
    }

    &__icon {
      svg {
        width: 193px;
        height: 80px;
      }
    }
  }
}