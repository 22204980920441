.genplan-quarter {

}

@include respond-up('large') {
  .genplan-quarter {
    height: 880px;
  }
}

@include respond('s-large') {
  .genplan-quarter {
    height: 710px;
  }
}

@include respond-up('medium') {
  .genplan-quarter {

  }
}

@include respond('medium') {
  .genplan-quarter {
    height: 680px;
  }
}

@include respond-down('medium') {
  .genplan-quarter {

  }
}

@include respond-down('small') {
  .genplan-quarter {
    height: 600px;
  }
}