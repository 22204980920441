.plannings-list {
  &__layout-name {
    font-weight: 600;
    line-height: 160%;
    &.h6 {
      text-transform: unset;
    }
  }

  &._filter-top {
    .plannings-list {
      &__item-link {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
      }

      &__picture {
        display: flex;
        width: 100%;
      }

      &__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &__layout-info-wrap {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
      }

      &__info {
        display: flex;
        flex-direction: row;
      }

      &__layout-price-wrap {
        border-top: 1px solid var(--color-grey-02);
      }

      &__item {
        background: var(--color-add-04);
      }

      &__image-wrap,
      &__layout-about-wrap {
        display: flex;
      }

      &__layout-about-wrap {
        flex-direction: column;
      }
    }
  }
}

@include respond-up('large') {
  .plannings-list {
    &._filter-top {
      .plannings-list {
        &__list-wrapper {
          margin-top: 40px;
        }

        &__item {
          box-shadow: 0 2px 25px rgba(0, 0, 0, 0.07);
          transition: box-shadow var(--default-timing) var(--default-bezier);

          &:hover {
            box-shadow: 0 1px 25px rgba(0, 0, 0, 0.13);

            .plannings-list {
              &__layout-name {
                color: var(--color-add-05);
              }
            }
          }
        }

        &__item-link {
          padding: 60px 20px 40px;
        }

        &__picture {
          height: calc((var(--grid-column2) + (var(--grid-gap) + 12px)) * 214 / 257);
        }

        &__image-wrap {
          padding: 0 20px;
          display: flex;
        }

        &__layout-about-wrap {
          margin-top: 20px;
          padding: 0 10px;
        }

        &__layout-price-wrap {
          margin-top: 20px;
          padding: 20px 10px 0;
        }

        &__info-name {
          flex: 0 0 var(--grid-column1);
        }

        &__info-value {
          margin-left: var(--grid-gap);
        }

        &__next-wrap {
          .pagination-endless {
            &__link {
              margin-top: 60px;
            }
          }
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .plannings-list {
    &__layout-name {
      font-size: 18px;
    }

    &._filter-side,
    &._filter-side-alt {
      .plannings-list {
        &__layout-name {
          font-size: 24px;
          line-height: 155%;
        }
      }
    }

    &._filter-top {
      .plannings-list {
        &__item {
          grid-column: span 3;
          border-radius: 20px;
        }

        &__favorites {
          top: 20px;
          right: 20px;
        }

        &__layout-info-wrap {
          margin-top: 10px;
        }
      }
    }
  }
}

@include respond('s-large') {
  .plannings-list {
    &._filter-top {
      .plannings-list {
        &__list-wrapper {
          margin-top: 30px;
        }

        &__item {
          box-shadow: 0 1px 25px rgba(0, 0, 0, 0.07);
        }

        &__item-link {
          padding: 50px 17px 40px;
        }

        &__image-wrap {
          padding: 0 17px;
        }

        &__layout-about-wrap {
          margin-top: 17px;
          padding: 0 7px;
        }

        &__layout-price-wrap {
          padding: 20px 7px 0;
          margin-top: 10px;
        }

        &__picture {
          height: calc((var(--grid-column2) + (var(--grid-gap) + 9px)) * 178 / 214);
        }

        &__info-name {
          flex: 0 0 calc(var(--grid-column) + var(--grid-gap));
        }

        &__info-value {
          margin-left: 14px;
        }

        &__next-wrap {
          .pagination-endless {
            &__link {
              margin-top: 40px;
            }
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .plannings-list {
    &._filter-top {
      .plannings-list {
        &__layout-price-info {
          display: none;
        }

        &__info {
          &_area {
            display: none;
          }
        }
      }
    }
  }
}

@include respond('medium') {
  .plannings-list {
    &._filter-top {
      .plannings-list {
        &__item {
          grid-column: span 2;
        }

        &__item-link {
          padding: 50px 16px 30px;
        }

        &__layout-price-wrap {
          margin-top: 10px;
          padding-top: 20px;
        }

        &__image-wrap,
        &__layout-about-wrap,
        &__layout-price-wrap {
          padding-left: 6px;
          padding-right: 6px;
        }

        &__picture {
          height: calc((var(--grid-column2) - 40px) * 145 / 176);
        }

        &__info-name {
          flex: 0 0 var(--grid-column);
        }

        &__favorites {
          top: 16px;
          right: 16px;
        }

        &__layout-about-wrap {
          margin-top: 15px;
        }

        &__list-wrapper {
          margin-top: 20px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .plannings-list {
    &__layout-name {
      font-size: 16px;
    }

    &._filter-top {
      .plannings-list {
        &__item {
          box-shadow: 0 1px 25px rgba(0, 0, 0, 0.07);
        }

        &__layout-info-wrap {
          margin-top: 5px;
        }

        &__next-wrap {
          .pagination-endless {
            &__link {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .plannings-list {
    &._filter-top {

    }
  }
}