.layout-modal-base {
  &__main {
    display: grid;
  }

  &__form-wrap {
    background-color: var(--color-grey-01);
  }

  &__subtitle {
    color: var(--color-grey-03);
    margin-top: 4px;
  }

  &__title-wrap {
    display: flex;
  }

  &__title {
    display: flex;
  }

  &__actions-wrap {
    display: flex;
  }
}

@include respond-up('large') {
  .layout-modal-base {
    &__right {
      padding-top: 60px;
    }
  }
}

@include respond-up('s-large') {
  .layout-modal-base {
    width: var(--grid-column12);

    &__main {
      padding: 0 calc(var(--grid-column) + var(--grid-gap)) 40px;
      grid-template-columns: repeat(10, var(--grid-column));
      grid-template-rows: auto 1fr;
      grid-column-gap: var(--grid-gap);
    }

    &__form-wrap {
      padding: 0 calc(var(--grid-column) + var(--grid-gap));
    }

    &__heading {
      grid-column: 1/6;
      grid-row: 1/2;
      width: calc(var(--grid-column5) - 40px);
    }

    &__left {
      position: relative;
      grid-column: 1/6;
      grid-row: 2/4;
      padding-right: 40px;
      display: flex;
      flex-direction: column;

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 1px;
        height: calc(100% + 31px);
        background-color: var(--color-grey-02);
      }
    }

    &__right {
      grid-column: 6/11;
      grid-row: 1/3;
    }

    &__actions-wrap {
      gap: 8px;
    }

    &__title {
      width: 100%;
    }
  }
}

@include respond('s-large') {
  .layout-modal-base {
    &__right {
      padding-top: 54px;
    }
  }
}

@include respond-up('medium') {
  .layout-modal-base {
    &__title {
      align-items: center;
      justify-content: space-between;
    }
  }
}

@include respond('medium') {
  .layout-modal-base {
    width: var(--grid-column6);
  }
}

@include respond-down('medium') {
  .layout-modal-base {
    &__main {
      padding: 0 var(--grid-spacer) 30px;
      grid-template-columns: 1fr;
    }

    &__form-wrap {
      padding: 0 var(--grid-spacer);
    }

    &__heading {
      grid-column: 1/2;
      grid-row: 1/2;
    }

    &__left {
      grid-column: 1/2;
      grid-row: 3/4;
    }

    &__right {
      grid-column: 1/2;
      grid-row: 2/3;
    }

    &__actions-wrap {
      gap: 6px;
    }

    &__title {
      gap: 10px;
      align-items: center;
    }
  }
}

@include respond-down('small') {
  .layout-modal-base {
    width: 100vw;

    &__sber-action-link {
      right: -44px;
    }
  }
}