.hero_three {
  --hero-height: calc(var(--vh, 1vh) * 100 - var(--header-height));

  .hero {
    &__text-title {
      @extend .h1;
    }
  }

  .hero-slider-info {
    background-color: var(--color-grey-01);
    border-bottom: 1px solid var(--color-grey-02);
  }

  .hero-info {
    &__address,
    &__logo {
      display: none;
    }
  }
}

@include respond-up('large') {
  .hero_three {
    .hero {
      &__text-description {
        margin-top: 6px;
      }

      &__text-link {
        margin-top: 60px;
      }
    }

    .hero-slider-info {
      padding: 40px 0;
    }
  }
}

@include respond-up('s-large') {
  .hero_three {
    .hero {
      &__text-title {
        width: var(--grid-column6);
      }

      &__text-description {
        width: var(--grid-column5);
      }
    }

    .hero-slider-info {
      &__inner {
        align-items: center;
      }

      &__logo {
        grid-column: 1/2;
      }

      &__address {
        grid-column: 2/5;
      }

      &__developer {
        grid-column: 5/8;
      }

      &__deadline {
        grid-column: 8/11;
      }
    }

    .hero-info {
      &__video-link-wrap {
        display: none;
      }

      &__description {
        grid-row: 1/2;
      }

      &__logo,
      &__list {
        grid-row: 2/3;
      }
    }
  }
}

@include respond('s-large') {
  .hero_three {
    .hero {
      &__text-description {
        margin-top: 10px;
      }

      &__text-link {
        margin-top: 50px;
      }
    }

    .hero-slider-info {
      padding: 30px 0 40px;
    }
  }
}

@include respond-up('medium') {
  .hero_three {

  }
}

@include respond('medium') {
  .hero_three {
    .hero {
      &__text-description {
        margin-top: 16px;
      }

      &__text-link {
        margin-top: 41px;
      }
    }

    .hero-info {
      &__inner {
        padding-top: 40px;
      }

      &__video-link-wrap {
        grid-column: 6/7;
        grid-row: 1/4;
        justify-content: flex-end;
      }

      &__video-link {
        margin-top: unset;
      }

      &__video-title {
        display: none;
      }
    }
  }
}

@include respond-down('medium') {
  .hero_three {
    .hero-slider-info {
      &__logo {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .hero_three {
    .hero {
      &__text-description {
        margin-top: 10px;
      }

      &__text-link {
        margin-top: 31px;
      }

      &__slider-content-inner {
        padding-bottom: 84px;
      }
    }

    .hero-info {
      &__video-link-wrap {
        display: none;
      }
    }
  }
}