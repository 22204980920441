.apartment-modal {
  &__price-info {
    margin-top: 30px;
  }

  .layout-info-list {
    &__item {
      &:first-child {
        border-top: none;
      }
    }
  }
}

@include respond-up('large') {
  .apartment-modal {
    &__price-block {
      margin-top: 40px;
    }

    .layout-modal-base {
      &__left {
        margin-top: 25px;
      }
    }
  }
}

@include respond('s-large') {
  .apartment-modal {
    .layout-modal-base {
      &__left {
        margin-top: 20px;
      }
    }
  }
}

@include respond-down('s-large') {
  .apartment-modal {
    &__price-block {
      margin-top: 20px;
    }
  }
}

@include respond-up('medium') {
  .apartment-modal {

  }
}

@include respond('medium') {
  .apartment-modal {
    .layout-modal-base {
      &__left {
        margin-top: 33px;
      }

      &__right {
        margin-top: 40px;
      }
    }
  }
}

@include respond-down('medium') {
  .apartment-modal {

  }
}

@include respond-down('small') {
  .apartment-modal {
    .layout-modal-base {
      &__left {
        margin-top: 25px;
      }

      &__right {
        margin-top: 32px;
      }
    }
  }
}