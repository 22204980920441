.request-form {
  position: relative;

  &__data {
    position: relative;
    z-index: 1;
    transition: all 0.4s;
  }

  &__success {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transition: {
      property: opacity, transform, visibility;
      duration: 0.4s;
    };
    z-index: 100;
    display: flex;
    flex-direction: column;
    //align-items: center;
    //justify-content: center;
    //transform: translate3d(0,40px,0);
  }

  &.success {
    .request-form {
      &__data {
        //opacity: 0.05;
        opacity: 0;
      }

      &__success {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
        //background: var(--background-color);
        background: var(--background-footer-color);
        height: min-content;
      }
    }
  }

  &__success-icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--color-more-add-03);
  }
}

@include respond-up('large') {
  .request-form {
    &_footer {
      margin-top: 40px;

      .request-form {
        &__success-title {
          margin-top: 20px;
        }

        &__success-description {
          margin-top: 10px;
        }

        &__success {
          top: calc(-97% + 20px);
          padding-top: 80px;
          margin-top: -80px;
        }
      }
    }
  }
}

@include respond-up('s-large') {
  .request-form {
    &__success-icon-wrap {
      width: 52px;
      height: 52px;
    }

    &.success {
      .request-form {
        &__data {
          //opacity: 0.05;
          opacity: 0;
        }
      }
    }
  }
}

@include respond('s-large') {
  .request-form {
    &_footer {
      margin-top: 32px;

      .request-form {
        &__success-title {
          margin-top: 20px;
        }

        &__success-description {
          margin-top: 12px;
        }

        &__success {
          top: calc(-106% + 20px);
          padding-top: 60px;
          margin-top: -60px;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .request-form {
    &_footer {
     .request-form {
       &__success-text-wrap {
         width: var(--grid-column5);
       }
     }
    }
  }
}

@include respond('medium') {
  .request-form {
    &_footer {
      margin-top: 28px;

      .request-form {
        &__success-title {
          margin-top: 30px;
        }

        &__success-description {
          margin-top: 9px;
        }

        &__success {
          top: calc(-92% + 18px);
          padding-top: 59px;
          margin-top: -59px;
        }
      }
    }

    &.success {
      .request-form {
        &__success {
          //transform: translate3d(0,-240px,0);
          //padding-top: 100px;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .request-form {
    &__success-icon-wrap {
      width: 42px;
      height: 42px;
    }
  }
}

@include respond-down('small') {
  .request-form {
    &_footer {
      margin-top: 14px;

      .request-form {
        &__success-text-wrap {
          width: var(--grid-column4);
        }

        &__success-title {
          margin-top: 20px;
        }

        &__success {
          top: calc(-30% + 10px);
          padding-top: 40px;
          margin-top: -40px;
        }
      }
    }

    &.success {
      .request-form {
        &__success {
          //transform: translate3d(0,-120px,0);
          //padding-top: 50px;
        }
      }
    }
  }
}