.plannings-card {
  &__picture {
    display: flex;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__plannings-item {
    display: flex;

    &:not(:last-child) {
      .plannings-card {
        &__item-text-wrap {
          position: relative;

          &:before {
            position: absolute;
            content: '';
            background: var(--color-add-05);
            left: 0;
            height: 2px;
            z-index: 100;
            transition: opacity var(--default-timing) var(--default-bezier);
            opacity: 0;
          }
        }
      }
    }

    &:not(:first-child) {
      .plannings-card {
        &__item-text-wrap {
          border-top: 1px solid var(--color-grey-02);
        }
      }
    }

    &:first-child {
      .plannings-card {
        &__picture {
          opacity: 1;
        }
      }
    }

    &:hover {
      .plannings-card {
        &__layout-name,
        &__area-total,
        &__picture,
        &__item-text-wrap:before {
          opacity: 1 !important;
        }

        &__item-image-wrap {
          pointer-events: auto;
          z-index: 100;
        }
      }
    }
  }

  &__item-text-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__layout-name,
  &__area-total,
  &__picture {
    transition: opacity var(--default-timing) var(--default-bezier);
  }

  &__area-total {
    opacity: 0;
  }

  &__plannings-list {
    &:hover {
      .plannings-card {
        &__layout-name {
          opacity: 0.4;
        }
      }
    }
  }

  &__picture {
    opacity: 0;
  }

  &__plannings-wrap {
    position: relative;
  }

  &__item-image-wrap {
    position: absolute;
    display: flex;
    justify-content: center;
    pointer-events: none;
  }

  &__plannings-list {
    margin-top: 40px;
  }
}

@include respond-up('large') {
  .plannings-card {
    min-height: calc((var(--grid-gap) * 2 + var(--grid-column4)) * 527/520);

    &__picture {
      height: calc((var(--grid-gap) * 2 + var(--grid-column4)) * 527/520);
    }

    &__item-text-wrap {
      &:before {
        width: calc(var(--grid-gap) + var(--grid-column2));
      }
    }

    &__button-wrap {
      margin-top: 80px;
    }
  }
}

@include respond-up('s-large') {
  .plannings-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__plannings-item {
      padding: 24px 0;

      &:not(:first-child) {
        .plannings-card {
          &__item-text-wrap {
            padding-top: 24px;
            margin-top: -24px;
          }
        }
      }

      &:last-child {
        margin-bottom: -24px;
      }

      &:first-child {
        margin-top: -24px;
      }
    }

    &__item-text-wrap {
      flex: 0 0 calc(var(--grid-gap) + var(--grid-column5));
    }

    &__item-image-wrap {
      margin-left: var(--grid-gap);
      width: calc(var(--grid-gap) * 2 + var(--grid-column4));
      right: 0;
      top: 0;
    }

    &__picture {
      width: calc((var(--grid-gap) * 2 + var(--grid-column4)));
    }

    &__item-text-wrap {
      &:before {
        bottom: -25px;
      }
    }
  }
}

@include respond('s-large') {
  .plannings-card {
    min-height: calc((var(--grid-gap) * 2 + var(--grid-column4)) * 491/440);

    &__picture {
      height: calc((var(--grid-gap) * 2 + var(--grid-column4)) * 491/440);
    }

    &__item-text-wrap {
      &:before {
        width: calc((var(--grid-gap) * 2) + var(--grid-column2));
      }
    }

    &__button-wrap {
      margin-top: 60px;
    }
  }
}

@include respond-up('medium') {
  .plannings-card {

  }
}

@include respond('medium') {
  .plannings-card {
    &__plannings-item {
      padding: 20px 0;

      &:not(:first-child) {
        .plannings-card {
          &__item-text-wrap {
            padding-top: 20px;
            margin-top: -20px;
          }
        }
      }

      &:last-child {
        margin-bottom: -20px;
      }

      &:first-child {
        margin-top: -20px;
      }
    }

    &__item-text-wrap {
      &:before {
        width: calc(var(--grid-gap) + var(--grid-column2));
        bottom: -21px;
      }
    }

    &__item-image-wrap {
      width: var(--grid-column4);
    }

    &__picture {
      height: calc(var(--grid-column4) * 504/452);
    }

    &__image-wrap {
      height: calc(var(--grid-column4) * 504/452);
      margin-top: 40px;
    }

    &__button {
      width: calc((var(--grid-gap) * 2) + var(--grid-column2));
    }
  }
}

@include respond-down('medium') {
  .plannings-card {
    &__button-wrap {
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }

    &__item-text-wrap {
      width: 100%;
    }

    &__item-image-wrap {
      right: 50%;
      bottom: 0;
      transform: translateX(50%);
    }
  }
}

@include respond-down('small') {
  .plannings-card {
    &__plannings-item {
      padding: 16px 0;

      &:not(:first-child) {
        .plannings-card {
          &__item-text-wrap {
            padding-top: 16px;
            margin-top: -16px;
          }
        }
      }

      &:last-child {
        margin-bottom: -16px;
      }

      &:first-child {
        margin-top: -16px;
      }
    }

    &__item-text-wrap {
      &:before {
        width: calc(var(--grid-column2) + var(--grid-gap) + (var(--grid-column) - var(--grid-column) / 3));
        bottom: -17px;
      }
    }

    &__item-image-wrap,
    &__button {
      width: calc(var(--grid-column4) - 40px);
    }

    &__picture {
      height: calc((var(--grid-column4) - 40px) * 330/295);
    }

    &__image-wrap {
      height: calc((var(--grid-column4) - 40px) * 330/295);
      margin-top: 40px;
    }
  }
}