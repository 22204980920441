.ui {
  &_one {
    .animation-text {
      &__wrap {
        display: block;
        position: relative;
        overflow: hidden;
      }

      &__title {
        position: relative;
        display: block;
        transition: transform .47s cubic-bezier(.55, 0, .1, 1) 0ms;
      }

      &__title-duplicate {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transform: translateY(110%);
      }
    }
  }

  &_two {
  }

  &_three {
  }

  &_four {
  }
}

.animation-text {
  &__title-duplicate {
    display: none;
  }
}

@include respond-up('s-large') {
  .ui {
    &_one {
      .animation-text {
        &:hover {
          .animation-text {
            &__title {
              transform: translateY(-110%);
            }
          }
        }
      }
    }
  }
}