.hero_four {
  --hero-height: calc(var(--vh, 1vh) * 100);

  &.header {
    .link {
      --link-button-color: var(--color-add-04);
    }

    &._sticky {
      .link {
        --link-button-color: var(--color-main-01);
      }
    }
  }

  .hero {
    &__text-title {
      @extend .h1;
    }

    &__slider-info {
      display: none;
    }

    &__images-slider {
      &::before {
        height: 100%;
        background: #3D4850;
        opacity: 0.7;
      }
    }

    &__text-title {
      text-align: center;
    }

    &__text-description,
    &__text-link {
      display: none;
    }

    &__text-slide {
      bottom: 50vh;
      left: 50%;
      transform: translate3d(-50%, 50%, 0);
      display: flex;
      flex-direction: column;
      align-items: center;
      height: max-content;
    }

    &__view-modal,
    &__text-slide-logo {
      display: flex;
    }
  }

  .hero-info {
    &__title {
      display: none;
    }
  }
}

@include respond-up('large') {
  .hero_four {
    .hero {
      &__pagination {
        bottom: 80px;
      }
    }
  }
}

@include respond-up('s-large') {
  .hero_four {
    .hero {
      &__text-slide {
        width: var(--grid-column6);
      }
    }

    .hero-info {
      &__inner {
        grid-template-rows: max-content max-content 1fr;
      }

      &__logo,
      &__list {
        grid-row: 3/4;
      }
    }
  }
}

@include respond('s-large') {
  .hero_four {
    .hero {
      &__pagination {
        bottom: 60px;
      }
    }
  }
}

@include respond-up('medium') {
  .hero_four {
    .hero {
      &__pagination {
        bottom: 60px;
      }

      &__arrow-left {
        bottom: 50vh;
        transform: translate3d(0, 50%, 0);
        left: 0;
      }

      &__arrow-right {
        bottom: 50vh;
        transform: translate3d(0, 50%, 0);
      }
    }
  }
}

@include respond('medium') {
  .hero_four {
    .hero {
      &__text-slide {
        width: calc(var(--grid-column4) + var(--grid-gap) * 2);
      }
    }

    .hero-info {
      &__video-link-wrap {
        grid-column: 1/6;
      }
    }
  }
}

@include respond-down('medium') {
  .hero_four {
    .hero {
      &__pagination {
        bottom: 30px;
      }
    }
  }
}

@include respond-down('small') {
  .hero_four {
    .hero {
      &__arrow-left,
      &__arrow-right {
        bottom: 30px;
      }

      &__text-slide {
        width: var(--grid-column4);
      }
    }
  }
}