.infrastructure-two {
  .infrastructure {
    &__icon {
      display: flex;
      align-items: center;
      svg {
        width: 22px;
        height: 22px;
        path {
          stroke: var(--color-grey-03);
        }
      }
    }

    &__info-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__time-wrap {
      display: flex;
      align-items: center;
    }

    &__picture {
      display: flex;
      width: 100%;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@include respond-up('large') {
  .infrastructure-two {
    .infrastructure {
      &__grid-wrap {
        margin-top: 80px;
      }

      &__picture {
        height: calc(var(--grid-column3) * 389 / 338);
      }

      &__info-wrap {
        margin-top: 20px;
      }

      &__arrows {
        height: calc(var(--grid-column3) * 389 / 338);
      }
    }
  }
}

@include respond-up('s-large') {
  .infrastructure-two {
    .infrastructure {
      &__slider-wrap {
        grid-column: 5/13;
        grid-row: 1;
      }

      &__slider-item {
        width: var(--grid-column3);
      }

      &__slider-title {
        grid-column: 1/4;
        grid-row: 1;
      }

      &__arrows {
        grid-column: 1/4;
        grid-row: 1;
        align-items: flex-end;
      }

      &__time {
        margin-left: 4px;
      }

      &__image-wrap {
        width: var(--grid-column3);
      }

      &__slider-wrap {
        margin-right: calc(0px - var(--grid-spacer-and-indent));
      }

      &__slider {
        &.swiper {
          padding-right: var(--grid-spacer-and-indent);
        }
      }
    }
  }
}

@include respond('s-large') {
  .infrastructure-two {
    .infrastructure {
      &__grid-wrap {
        margin-top: 60px;
      }

      &__picture {
        height: calc(var(--grid-column3) * 320 / 278);
      }

      &__info-wrap {
        margin-top: 10px;
      }

      &__arrows {
        height: calc(var(--grid-column3) * 320 / 278);
      }
    }
  }
}

@include respond-up('medium') {
  .infrastructure-two {
    .infrastructure {

    }
  }
}

@include respond('medium') {
  .infrastructure-two {
    .infrastructure {
      &__grid-wrap {
        margin-top: 60px;
        grid-row-gap: 0;
      }

      &__slider-title {
        grid-column: 1/6;
      }

      &__slider-wrap {
        grid-column: 1/7;
        margin-top: 30px;
      }

      &__arrows {
        grid-column: 1/2;
        margin-top: 30px;
      }

      &__slider-item,
      &__image-wrap {
        width: calc(var(--grid-column2) + var(--grid-gap) + 20px);
      }

      &__picture {
        height: calc((var(--grid-column2) + var(--grid-gap) + 20px) * 318 / 276);
      }

      &__info-wrap {
        margin-top: 12px;
      }
    }
  }
}

@include respond-down('medium') {
  .infrastructure-two {
    .infrastructure {
      &__time {
        margin-left: 2px;
      }

      &__slider-wrap {
        margin-left: calc(0px - var(--grid-spacer-and-indent));
        margin-right: calc(0px - var(--grid-spacer-and-indent));
      }

      &__slider {
        &.swiper {
          padding-right: calc(var(--grid-spacer-and-indent) * 2);
        }
      }

      &__slider-list {
        &.swiper-wrapper {
          padding-left: var(--grid-spacer-and-indent);
        }
      }

      &__slider-title {
        grid-row: 1;
      }

      &__slider-wrap {
        grid-row: 2;
      }

      &__arrows {
        grid-row: 3;
      }
    }
  }
}

@include respond-down('small') {
  .infrastructure-two {
    .infrastructure {
      &__grid-wrap {
        margin-top: 40px;
        grid-row-gap: 0;
      }

      &__slider-title {
        grid-column: 1/5;
      }

      &__slider-wrap {
        grid-column: 1/5;
        margin-top: 8px;
      }

      &__arrows {
        grid-column: 1/3;
        margin-top: 24px;
      }

      &__slider-item,
      &__image-wrap {
        width: var(--grid-column3);
      }

      &__picture {
        height: calc(var(--grid-column3) * 286 / 248);
      }

      &__info-wrap {
        margin-top: 10px;
      }
    }
  }
}