.arrows {
  display: flex;
}

@include respond-up('s-large') {
  .arrows {
    &__right {
      margin-left: 16px;
    }
  }
}

@include respond-down('medium') {
  .arrows {
    &__right {
      margin-left: 10px;
    }
  }
}