.c-genplan {
  &__gen {
    height: 100vh;
    width: 100%
  }

  &__gen-interface {
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2000;

    &._full-screen {
      height: 100vh;
    }
  }

  &__interface-action {
    pointer-events: auto;
    cursor: pointer;
  }

  &__interface-button-text {
    color: #fff;
  }

  &__placemark {
    svg {
      display: flex;
    }

    &_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background-color: var(--color-add-04);
      width: 38px;
      height: 38px;
    }

    &._hidden {
      display: none;
    }
  }

  &__placemark-number {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    width: 50px;
    height: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    position: relative;

    // TODO: что с цветами
    color: #fff;
    background-color: var(--orange-color);

    &:before {
      position: absolute;
      content: '';
      background-color: var(--orange-color);
      border-radius: 100px;
      width: 50px;
      height: 50px;
      animation-duration: 1.4s;
      animation-name: point-pulse;
      animation-iteration-count: infinite;
      z-index: 10;
      left: 0;
      top: 0;
    }
  }

  &__placemark-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 38px;

    svg {
      path {
        stroke: var(--color-main-01);
        transition: stroke var(--default-timing) var(--default-transition-function);
      }

      circle {
        fill: var(--color-grey-01);
        transition: fill var(--default-timing) var(--default-transition-function);
      }
    }

    //img {
    //  width: 22px;
    //  height: 22px;
    //}
  }

  &__placemark-number-text {
    position: relative;
    z-index: 100;
  }

  &__floor-placemark {
    width: 2px;
    height: 30px;
    background-color: transparent;
    display: block;
  }

  &__interface-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &__interface-infra-checkbox {
    position: absolute;
  }

  &__interface-back {
    position: absolute;
    display: flex;
    align-items: center;
  }

  &__back-button-icon {
    background-color: var(--color-add-04-60);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    //border: 1px solid var(--color-border);

    svg {
      path {
        stroke: var(--color-main-01);
      }
    }
  }

  &__back-button-text {
    font-style: normal;
    font-weight: 600;
    line-height: 155%;
    color: var(--color-add-04);
  }

  &__infra-balloon {
    &.genplan__object-balloon._popover {
      padding: 5px 10px;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
      }
    }
  }

  &__infra-balloon-title {
    white-space: nowrap;
  }
}

@include respond-up('large') {
  .c-genplan {
    &__interface-back {
      top: 60px;
    }

    &__interface-infra-checkbox {
      bottom: 60px;
    }

    &__interface-button {
      padding-left: 40px;
      padding-right: 40px;

      .button__text {
        margin-left: 15px;
        color: #fff;
      }
    }
  }
}

@include respond-up('s-large') {
  .c-genplan {
    &__placemark-icon {
      &:hover {
        svg {
          path {
            stroke: var(--color-grey-01);
          }

          circle {
            fill: var(--color-main-01);
          }
        }
      }
    }

    &__interface-back,
    &__interface-infra-checkbox {
      left: var(--grid-spacer-and-indent);
    }

    &__back-button-icon {
      width: 52px;
      height: 52px;
    }

    &__back-button-text {
      margin-left: 14px;
      font-size: 24px;
    }
  }
}

@include respond('s-large') {
  .c-genplan {
    &__interface-back {
      top: 50px;
    }

    &__interface-infra-checkbox {
      bottom: 40px;
    }
  }
}

@include respond('medium') {
  .c-genplan {
    &__interface-back {
      top: 40px;
    }

    &__interface-infra-checkbox {
      bottom: 30px;
    }
  }
}

@include respond-down('medium') {
  .c-genplan {
    &__interface-back,
    &__interface-infra-checkbox {
      left: var(--grid-spacer);
    }

    &__back-button-icon {
      width: 42px;
      height: 42px;
    }

    &__back-button-text {
      margin-left: 12px;
      font-size: 18px;
    }
  }
}

@include respond-down('small') {
  .c-genplan {
    &__interface-back {
      top: 20px;
    }

    &__interface-infra-checkbox {
      bottom: 20px;
    }
  }
}

@keyframes point-pulse {
  0% {
    opacity: 1;
    transform: scale(0.8);
  }
  30% {
    opacity: 1;
    transform: scale(0.8);
  }
  to {
    opacity: 0;
    transform: scale(1.7);
  }
}