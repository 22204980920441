.infrastructure {
  &__wrapper {
    background-color: var(--background-footer-color);
    overflow: hidden;
  }
}

@include respond-up('large') {
  .infrastructure {
    padding: 80px 0;

    &__map-wrap {
      margin-top: 60px;
    }
  }
}

@include respond-up('s-large') {
  .infrastructure {

  }
}

@include respond('s-large') {
  .infrastructure {
    padding: 60px 0;

    &__map-wrap {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .infrastructure {

  }
}

@include respond('medium') {
  .infrastructure {
    &__map-wrap {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .infrastructure {
    padding: 40px 0;
  }
}

@include respond-down('small') {
  .infrastructure {
    &__map-wrap {
      margin-top: 25px;
    }
  }
}