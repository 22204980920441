.advantages-slider {
  border-top: 1px solid var(--color-grey-02);
  border-bottom: 1px solid var(--color-grey-02);

  &__nav {
    position: relative;
    display: flex;
    justify-content: space-between;
  }

  &__description {
    color: var(--color-grey-03);
  }

  &__pagination {
    &.swiper-pagination-custom {
      position: relative;
    }
  }

  &__picture-wrap {
    position: relative;
    overflow: hidden;
    transition: width 1s;
    width: 30%;
    height: 100%;
  }

  &__picture {
    display: flex;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__title,
  &__description {
    transition: opacity .5s, transform .5s;
  }

  &__text-item {
    &.swiper-slide {
      .advantages-slider {
        &__title,
        &__description {
          opacity: 0;
          transform: translate3d(0, 20px, 0);
        }
      }
    }

    &.swiper-slide-active {
      .advantages-slider {
        &__title {
          animation: advantage-slider-text-in .8s forwards;
        }

        &__description {
          animation: advantage-slider-text-in .8s .2s forwards;
        }
      }
    }
  }

  &__images-item {
    &.swiper-slide {
      width: 100%;
    }

    &.swiper-slide-active {
      .advantages-slider__picture-wrap {
        width: 100%;
      }
    }
  }
}

@include respond-up('large') {
  .advantages-slider {
    &__inner {
      padding: 60px 0;
    }
  }
}

@include respond-up('s-large') {
  .advantages-slider {
    &__nav {
      grid-column: 1/3;
      flex-direction: column;
    }

    &__text-wrap {
      grid-column: 3/7;
    }

    &__images-wrap {
      grid-column: 7/13;
    }

    &__text-slider {
      height: 100%;
    }

    &__text-item {
      &.swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &__images-item {
      &.swiper-slide {
        height: calc(var(--grid-column6) * (625 / 705));
        max-height: 705px;
      }
    }

    &__picture {
      height: calc(var(--grid-column6) * (625 / 705));
    }
  }
}

@include respond('s-large') {
  .advantages-slider {
    &__inner {
      padding: 50px 0;
    }
  }
}

@include respond-up('medium') {
  .advantages-slider {
    &__picture {
      width: var(--grid-column6);
    }
  }
}

@include respond('medium') {
  .advantages-slider {
    &__inner {
      padding: 30px 0 20px;
    }

    &__nav,
    &__text-wrap,
    &__images-wrap {
      grid-column: 1/7;
    }

    &__images-item {
      &.swiper-slide {
        height: calc((382 / 768) * 100vw);
      }
    }

    &__picture {
      height: calc((382 / 768) * 100vw);
    }

    &__text-wrap,
    &__nav {
      margin-top: 30px;
    }

    &__description {
      margin-top: 20px;
    }
  }
}

@include respond-down('medium') {
  .advantages-slider {
    &__nav {
      order: 3;
    }

    &__text-wrap {
      order: 2;
    }

    &__images-wrap {
      order: 1;
    }
  }
}

@include respond-down('small') {
  .advantages-slider {
    &__inner {
      padding: 20px 0;
    }

    &__nav,
    &__text-wrap,
    &__images-wrap {
      grid-column: 1/5;
    }

    &__images-item {
      &.swiper-slide {
        height: calc((186 / 375) * 100vw);
      }
    }

    &__picture {
      width: var(--grid-column4);
      height: calc((186 / 375) * 100vw);
    }

    &__text-wrap,
    &__nav {
      margin-top: 20px;
    }

    &__description {
      margin-top: 8px;
    }
  }
}

@keyframes advantage-slider-text-in {
  from {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}