.pattern {
  background-size: contain;
  background-repeat-y: no-repeat;
  background-position-y: bottom;

  &_wide {
    margin: 0 calc(0px - var(--grid-spacer-and-indent));
  }
}

@include respond-up('large') {
  .pattern {
    height: 76px;
  }
}

@include respond('s-large') {
  .pattern {
    height: 63px;
  }
}

@include respond('medium') {
  .pattern {
    height: 50px;
  }
}

@include respond-down('small') {
  .pattern {
    height: 40px;
  }
}