.advantages {

}

@include respond-up('large') {
  .advantages {
    padding-bottom: 120px;

    &__main {
      margin-top: 60px;
    }
  }
}

@include respond('s-large') {
  .advantages {
    padding-bottom: 100px;

    &__main {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .advantages {

  }
}

@include respond('medium') {
  .advantages {
    &__main {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .advantages {
    padding-bottom: 80px;
  }
}

@include respond-down('small') {
  .advantages {
    &__main {
      margin-top: 25px;
    }
  }
}