.infra-checkbox {
  position: relative;
  display: flex;
  border-radius: var(--infra-checkbox-border-radius);
  background-color: var(--color-add-04);
  pointer-events: auto;

  input {
    display: none;

    + label {
      padding: 12px 16px 13px 70px;
      cursor: pointer;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
        transition: transform, background-color, border-color;
        transition-duration: .2s;
        transition-timing-function: ease-in-out;
      }

      &::before {
        left: 16px;
        height: 24px;
        width: 44px;
        box-sizing: border-box;
        border-radius: 31px;
        background-color: var(--infra-checkbox-inactive-bg);
        border: 1px solid var(--infra-checkbox-inactive-border-color);
      }

      &::after {
        height: 18px;
        width: 18px;
        left: 20px;
        border-radius: 100%;
        background-color: var(--infra-checkbox-inactive-switcher-bg);
      }
    }

    &:checked {
      & + label {
        &::before {
          background-color: var(--infra-checkbox-active-bg);
          border-color: var(--infra-checkbox-active-border-color);
        }

        &::after {
          transform: translate3d(18px, -50%, 0);
          background-color: var(--infra-checkbox-active-switcher-bg);
        }
      }
    }
  }
}

@include respond-down('small') {
  .infra-checkbox {
    display: none;
  }
}