@media print {
  .footer-print {
    display: flex;
    justify-content: space-between;
    padding: 6.3mm 12.9mm 6.9mm;
    align-items: flex-start;
    width: 100%;
    border-top: .2mm solid var(--color-grey-02);

    &__schedule-list {
      display: flex;
    }

    &__info-wrap {
      margin-top: 1mm;
    }

    &__right {
      display: flex;
    }

    &__left,
    &__right-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 21mm;
    }

    &__right {
      text-align: right;
      height: 28mm;
      padding-bottom: 5px;
    }

    &__right-qrcode {
      margin-left: 2mm;
      height: 28mm;
      width: 28mm;

      img {
        height: 28mm;
        width: 28mm;
        margin: -3.5mm;
      }
    }
  }
}