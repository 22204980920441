.advantages-list {
  &__item {
    position: relative;
    border-top: 1px solid var(--color-grey-02);
    background-color: var(--color-grey-01);
  }

  &__index {
    color: var(--color-grey-02);
    grid-column: 1/2;
  }

  &__description {
    color: var(--color-grey-03);
  }

  &__picture {
    display: flex;
    width: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .advantages-list {
    &__item-inner {
      padding: 60px 0 110px;
    }

    &__picture {
      height: calc((598 / 1600) * 100vw);
      max-height: 598px;
      margin-top: 40px;
    }

    &__description {
      margin-top: 40px;
    }
  }
}

@include respond-up('s-large') {
  .advantages-list {
    &__content {
      grid-column: 4/13;
    }
  }
}

@include respond('s-large') {
  .advantages-list {
    &__item-inner {
      padding: 30px 0 100px;
    }

    &__picture {
      height: calc((499 / 1280) * 100vw);
      max-height: 598px;
      margin-top: 30px;
    }

    &__description {
      margin-top: 30px;
    }
  }
}

@include respond-up('medium') {
  .advantages-list {

  }
}

@include respond('medium') {
  .advantages-list {
    &__content {
      grid-column: 2/7;
    }

    &__item {
      padding: 30px 0 90px;
    }

    &__picture {
      margin-top: 20px;
      height: calc((319 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .advantages-list {
    &__description {
      margin-top: 20px;
    }
  }
}

@include respond-down('small') {
  .advantages-list {
    &__content {
      grid-column: 1/5;
      margin-top: 8px;
    }

    &__item {
      padding: 30px 0 60px;
    }

    &__picture {
      margin-top: 14px;
      height: calc((187 / 375) * 100vw);
    }
  }
}