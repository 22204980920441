.popup-modal-resolve {
  display: flex;
  flex-direction: column;

  &__subtitle {
    margin-top: 10px;
  }

  &__button {
    margin-top: 40px;
  }

  &__timer {
    margin-top: auto;
  }
}

@include respond-up('large') {
  .popup-modal-resolve {
    padding: 142px 40px 60px;
  }
}

@include respond('s-large') {
  .popup-modal-resolve {
    padding: 72px 40px 40px;

    &__subtitle {
      margin-top: 15px;
    }
  }
}

@include respond-up('medium') {
  .popup-modal-resolve {
    &__button {
      max-width: max-content;
    }
  }
}

@include respond('medium') {
  .popup-modal-resolve {
    padding: 80px 40px 40px;
  }
}

@include respond-down('medium') {
  .popup-modal-resolve {
    &__timer {
      margin-top: 80px;
    }
  }
}

@include respond-down('small') {
  .popup-modal-resolve {
    padding: 122px 20px 30px;

    &__button {
      max-width: var(--grid-column3);
    }
  }
}