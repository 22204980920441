.about-item {
  &__contents {
    margin-top: 20px;
  }

  &__picture {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__description-top,
  &__description-bottom {
    color: var(--color-grey-03);
  }

  &__bottom {
    display: flex;
  }

  &__slider {
    position: relative;
  }

  &__content {
    &_no-slider {
      .about-item {
        &__bottom {
          flex-direction: column;
        }
      }
    }
  }
}

@include respond-up('large') {
  .about-item {
    &__arrow-left {
      left: 40px;
    }

    &__arrow-right {
      right: 40px;
    }
  }
}

@include respond-up('s-large') {
  .about-item {
    &__slider-wrapper {
      display: grid;
      grid-template-columns: var(--grid-column5) var(--grid-column4);
      grid-column-gap: var(--grid-gap);

      .about-item {
        &__single-image {
          width: var(--grid-column4);
          height: calc(var(--grid-column4) * (386 / 460));
        }

        &__slide.swiper-slide {
          height: calc(var(--grid-column5) * 720 / 582);
        }
      }
    }

    &__slider {
      &_vertical {
        width: var(--grid-column5);
        height: calc(var(--grid-column5) * 720 / 582);
      }
    }

    &__vertical-bottom {
      margin-top: 30px;

      .about-item__description-bottom {
        margin-top: 30px;
      }
    }

    &__slide.swiper-slide {
      height: calc(var(--grid-column9) * (580 / 1072));
    }

    &__single-image {
      width: var(--grid-column4);
      height: calc(var(--grid-column4) * (308 / 460));
    }

    &__bottom_with-image {
      .about-item {
        &__description-bottom {
          flex: 0 1 var(--grid-column3);
          margin-left: calc(var(--grid-column) + var(--grid-gap) * 2);
        }
      }
    }

    &__content {
      &_no-slider {
        .about-item {
          &__single-image {
            width: var(--grid-column9);
            height: calc(var(--grid-column9) * (580 / 1072));
          }

          &__description-bottom {
            margin-top: 30px;
            margin-left: 0;
          }
        }
      }
    }
  }
}

@include respond('s-large') {
  .about-item {
    &__arrow-left {
      left: 30px;
    }

    &__arrow-right {
      right: 30px;
    }
  }
}

@include respond-up('medium') {
  .about-item {
    &__tabs {
      margin-top: 30px;
    }

    &__slider {
      margin-top: 30px;
    }

    &__bottom {
      margin-top: 30px;

      &_with-image {
        margin-top: 40px;
      }
    }

    &__single-image {
      flex: 0 0 auto;
    }

    &__arrow-left,
    &__arrow-right {
      position: absolute;
      z-index: 25;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 30px;
      }
    }
  }
}

@include respond('medium') {
  .about-item {
    &__slider-wrapper {
      display: grid;
      grid-template-columns: calc(var(--grid-column3) + var(--grid-column1) / 2 + var(--grid-gap) / 2) calc(var(--grid-column2) + var(--grid-column1) / 2 + var(--grid-gap) / 2);
      grid-column-gap: var(--grid-gap);

      .about-item {
        &__single-image {
          width: calc(var(--grid-column2) + var(--grid-column1) / 2 + var(--grid-gap) / 2);
          height: calc((var(--grid-column2) + var(--grid-column1) / 2 + var(--grid-gap) / 2) / 460 * 386);
        }

        &__slide.swiper-slide {
          height: calc((var(--grid-column3) + var(--grid-column1) / 2 - var(--grid-gap) / 2) * 720 / 582);
        }
      }
    }

    &__slider {
      &_vertical {
        width: calc(var(--grid-column3) + var(--grid-column1) / 2 - var(--grid-gap) / 2);
        height: calc((var(--grid-column3) + var(--grid-column1) / 2 - var(--grid-gap) / 2) * 720 / 582);
      }
    }

    &__vertical-bottom {
      margin-top: 30px;

      .about-item__description-bottom {
        margin-top: 30px;
      }
    }

    &__slide.swiper-slide {
      height: calc(var(--grid-column6) * (384 / 688));
    }

    &__single-image {
      height: calc(var(--grid-column3) * (222 / 335));
    }

    &__bottom_with-image {
      .about-item {
        &__description-bottom {
          flex: 0 1 100%;
          margin-left: var(--grid-gap);
        }
      }
    }

    &__arrow-left {
      left: 20px;
    }

    &__arrow-right {
      right: 20px;
    }

    &__content {
      &_no-slider {
        .about-item {
          &__single-image {
            width: var(--grid-column6);
            height: calc(var(--grid-column6) * (384 / 688));
          }

          &__description-bottom {
            margin-top: 30px;
            margin-left: 0;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .about-item {
    &__single-image {
      width: var(--grid-column3);
    }
  }
}

@include respond-down('small') {
  .about-item {
    &__slider {
      &_vertical {
        //height: calc((var(--grid-column4)) * (414 / 335));
        width: 100%;

        .about-item {
          &__slide.swiper-slide {
            height: calc((var(--grid-column4)) * (414 / 335));
          }
        }
      }
    }

    &__slider-wrapper {
      .about-item {
        &__description-bottom {
          margin-top: 20px;
        }
      }
    }

    &__tabs {
      margin-top: 8px;
      overflow: scroll hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__slider {
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
    }

    &__vertical-bottom {
      display: flex;
      flex-direction: column-reverse;
    }

    &__bottom {
      margin-top: 20px;
      flex-direction: column-reverse;

      &_with-image {
        margin-top: 30px;
      }
    }

    &__slide.swiper-slide {
      height: calc(var(--grid-column4) * (186 / 335));
    }

    &__single-image {
      height: calc(var(--grid-column3) * (163 / 245));
      margin-top: 20px;

      &_wide {
        width: 100%;
        height: calc(var(--grid-column4) * (186 / 335));
      }
    }

    &__pagination {
      &.swiper-pagination-bullets.swiper-pagination-horizontal {
        bottom: 60px;
      }
    }

    &__slider-list {
      flex: 0 0 100%;
    }

    &__arrow-left {
      margin-top: 10px;
      margin-left: var(--grid-spacer);
    }

    &__arrow-right {
      margin-top: 10px;
      margin-left: 10px;
    }

    &__slider {
      .about-item {
        &__arrow-left,
        &__arrow-right {
          border-color: rgba(#000000, 0.4);

          svg {
            path {
              stroke: #000000;
            }
          }
        }
      }
    }

    &__content {
      &_no-slider {
        .about-item {
          &__single-image {
            width: var(--grid-column4);
            height: calc(var(--grid-column4) * (186 / 335));
            margin-top: 0;
            margin-left: var(--grid-spacer);
          }

          &__description-bottom {
            margin-top: 20px;
          }
        }
      }
    }

    &__content {
      margin: 0 calc(0px - var(--grid-spacer));
    }

    &__description-bottom,
    &__description-top {
      margin: 0 var(--grid-spacer);
    }
  }
}