.rslider-test {
  margin-top: 20px;
  position: relative;
  height: 500px;
  width: 100%;

  &__images-slider {
    display: flex;
    height: 100%;
  }

  &__images-slide {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.2;

    &._active {
      opacity: 1;
    }
  }

  &__texts-slider {
    z-index: 1500;
  }

  &__text {
    opacity: 0.2;

    &._active {
      opacity: 1;
    }
  }
}