.layout-modal {

}

@include respond-up('large') {
  .layout-modal {
    &__price-block {
      margin-top: 29px;
    }

    &__features {
      margin-top: 39px;
    }

    &__apartments {
      margin-top: 40px;
    }

    &__price-info {
      margin-top: 56px;
    }
  }
}

@include respond-up('s-large') {
  .layout-modal {
    .layout-modal-base {
      &__heading {
        border-bottom: 1px solid var(--color-grey-02);
        padding-bottom: 20px;
      }
    }
  }
}

@include respond('s-large') {
  .layout-modal {
    &__price-block {
      margin-top: 24px;
    }

    &__price-info {
      margin-top: 33px;
    }
  }
}

@include respond-down('s-large') {
  .layout-modal {
    &__features,
    &__apartments {
      margin-top: 30px;
    }
  }
}

@include respond-up('medium') {
  .layout-modal {

  }
}

@include respond('medium') {
  .layout-modal {
    .layout-modal-base {
      &__right {
        margin-top: 20px;
      }
    }
  }
}

@include respond-down('medium') {
  .layout-modal {
    .layout-modal-base {
      &__right {
        border-bottom: 1px solid var(--color-grey-02);
        padding-bottom: 30px;
      }
    }

    &__price-block {
      margin-top: 20px;
    }

    &__price-info {
      margin-top: 25px;
    }
  }
}

@include respond-down('small') {
  .layout-modal {
    .layout-modal-base {
      &__right {
        margin-top: 26px;
      }
    }

    &__features {
      margin-top: 26px;
    }
  }
}
