@media print {
  .layout-modal-base-print {

    &__page {
      width: 210mm;
      height: 297mm;
      display: flex;
      flex-direction: column;
      padding-bottom: 10mm;
    }

    &__content {
      margin-top: calc(25mm + 29.5mm); // head + heading
      flex: 1 1 auto;
    }

    &__heading {
      position: fixed;
      top: 25mm;
      left: 0;
      right: 0;
      padding: 7.1mm 10.6mm;
    }

    &__heading-base {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
    }

    &__subtitle {
      margin-top: .7mm;
    }

    &__article {
      font-size: 10pt;
    }

    &__content-part {
      padding: 0 10.6mm;

      &:not(:last-child) {
        border-bottom: .2mm solid var(--color-grey-02);
        padding-bottom: 7.9mm;
      }
    }

    &__image-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1mm;
    }

    &__image-wrap {
      overflow: hidden;

      &_planning {
        height: 102mm;

        .layout-modal-base-print__picture  {
          //height: calc(102mm + 10mm); // Обрезаем полоску
          height: 100%; // Не обрезаем полоску
        }
      }

      &_plan {
        .layout-modal-base-print__picture  {
          height: 85.2mm
        }
      }
    }

    &__picture {
      display: block;
    }

    &__image {
      object-fit: contain;
      object-position: center top;
      height: 100%;
      width: 100%;
    }

    &__image-name {
      text-align: center;
      margin-top: 5.3mm;
    }

    &__apartments {
      padding-top: 7mm;
      width: 50%;
    }
  }
}
