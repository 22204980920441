.hero_one {
  --hero-height: calc(var(--vh, 1vh) * 100 - var(--header-height));

  .hero {
    &__text-title {
      @extend .h1;
    }

    &__slider-info {
      display: none;
    }
  }

  .hero-info {
    &__title {
      display: none;
    }
  }
}

@include respond-up('large') {
  .hero_one {
    .hero {
      &__slider-content {
        padding-bottom: 79px;
      }

      &__text-description {
        width: var(--grid-column4);
      }
    }
  }
}

@include respond-up('s-large') {
  .hero_one {
    .hero {
      &__text-description {
        margin-top: 20px;
      }

      &__text-title {
        width: var(--grid-column12);
      }
    }

    .hero-info {
      &__inner {
        grid-template-rows: max-content max-content 1fr;
      }

      &__logo,
      &__list {
        grid-row: 3/4;
      }
    }
  }
}

@include respond('s-large') {
  .hero_one {
    .hero {
      &__slider-content {
        padding-bottom: 59px;
      }

      &__text-description {
        width: var(--grid-column5);
      }
    }
  }
}

@include respond-up('medium') {
  .hero_one {
    .hero {
      &__text-link {
        margin-top: 39px;
      }
    }
  }
}

@include respond('medium') {
  .hero_one {
    .hero {
      &__slider-content {
        padding-bottom: 40px;
      }

      &__text-title {
        width: var(--grid-column6);
      }

      &__text-description {
        margin-top: 12px;
        width: var(--grid-column5);
      }
    }

    .hero-info {
      &__video-link-wrap {
        grid-column: 1/6;
      }
    }
  }
}

@include respond-down('medium') {
  .hero_one {

  }
}

@include respond-down('small') {
  .hero_one {
    .hero {
      &__slider-content {
        padding-bottom: 30px;
      }

      &__text-description {
        margin-top: 9px;
      }

      &__text-link {
        margin-top: 31px;
      }

      &__text-title,
      &__text-description {
        width: var(--grid-column4);
      }

      &__slider-content-inner {
        padding-bottom: 84px;
      }
    }
  }
}