@media print {
  body * {
    visibility: hidden;
  }

  #printSection, #printSection * {
    visibility: visible;
  }

  #printSection {
    position: absolute;
    left: 0;
    top: 0;
  }

  html, body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  @page {
    size: A4 portrait;   /* auto is the initial value */
    margin: 0 !important;
    padding: 0 !important;
    background: white;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  .container_spacer {
    padding: 0 7.1mm;
  }

  .no-print {
    display: none;
  }

  .no-screen {
    display: block !important;
  }

  .print {
    &__layout_main {
      padding: 0;
    }

    &__block {
      page-break-inside: avoid;
      //page-break-after: always;
      //page-break-before: always;
    }

    &__headings {
      font-family: var(--h-font);
      font-weight: 500;
      color: var(--color-add-01);
      line-height: 155%;
    }

    &__body-text {
      font-family: var(--font);
      font-style: normal;
      font-weight: 400;
      color: var(--color-add-01);
      line-height: 120%;

      &_grey {
        color: var(--color-grey-03);
      }

      &_accent {
        color: var(--color-main-01);
      }

      &_bold {
        font-weight: 500;
      }
    }

    &__text09 {
      font-size: 9pt;
    }

    &__text10 {
      font-size: 10pt;
    }

    &__text11 {
      font-size: 11pt;
    }

    &__text16 {
      font-size: 16pt;
    }

    &__text18 {
      font-size: 18pt;
    }
  }
}

.no-screen {
  display: none;
}

@media screen {
  #printSection {
    display: none;
  }
}
