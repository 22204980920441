.social-icons {
  &__wrap {
    display: flex;
    column-gap: var(--socials-column-gap);
  }

  &__item {
    border-radius: var(--socials-radius);
    border: var(--socials-border);
    width: var(--socials-width-height);
    height: var(--socials-width-height);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background var(--default-timing) var(--default-bezier);

    svg {
      width: 100%;
      height: 100%;

      g {
        path {
          fill: var(--socials-bg);
          transition: fill var(--default-timing) var(--default-bezier);
        }
      }

      path {
        fill: var(--socials-bg);
        transition: fill var(--default-timing) var(--default-bezier);
      }
    }
  }
}

@include respond-up('s-large') {
  .social-icons {
    &__wrap {
      &:hover {
        .social-icons__item {
          svg {
            g {
              path {
                fill: var(--socials-wrap-item-hover);
              }
            }

            path {
              fill: var(--socials-wrap-item-hover);
            }
          }
        }
      }
    }

    &__item {
      &:hover {
        background: var(--socials-item-hover);

        svg {
          g {
            path {
              fill: var(--socials-hover) !important;
            }
          }

          path {
            fill: var(--socials-hover) !important;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .social-icons {

  }
}

@include respond('medium') {
  .social-icons {
    &__item {
      &:first-child {
        background: var(--socials-first-child-bg);

        svg {
          g {
            path {
              fill: var(--socials-first-child-icon);
            }
          }

          path {
            fill: var(--socials-first-child-icon);
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .social-icons {

  }
}
