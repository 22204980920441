:root {
  --modal-bg: rgba(0, 0, 0, 0.3);
  --modal-content-radius: 0px;
  --modal-content-shadow: none;
  --modal-content-background: #fff;
  --modal-closer-color: white;
  --modal-closer-size: 30px;
  --modal-preloader-size: 30px;
}

.modal {
  &__bg {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    background: var(--t);
    transition: background .4s var(--teaser-transition-func);

    &._opened {
      background: var(--modal-bg);
    }

    &._closed {
      background: var(--t);
    }
  }

  &__layout {
    display: none;
    position: relative;
    z-index: 11000;
    cursor: default;
    margin: 0 auto;
    min-height: 100%;
    align-items: center;
    justify-content: center;

    &._opened {
      display: flex;
    }

    &._layout-modal,
    &._floor-modal {
      align-items: flex-start;
      max-height: calc(var(--vh, 1vh) * 100);
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__container {
    padding: var(--modal-content-padding);
    border-radius: var(--modal-content-radius);
    background: var(--modal-content-background);
    box-shadow: var(--modal-content-shadow);
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }

    &._opened {
    }

    &._closed {
    }
  }

  .modal__content {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__closer {
    position: absolute;
    z-index: 50;
    border: 2px solid var(--color-grey-02-60);
    border-radius: 100%;

    justify-content: center;
    display: flex;
    align-items: center;

    cursor: pointer;

    &:before,
    &:after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      background: var(--color-grey-02);
      height: 2px;
    }

    &:before {
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }

    &:after {
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }
  }
}

body.modal-loading {
  overflow: hidden;
  //--preloader-size: 100px;

  //пульсирующий кружочек раньше был
  &__loader {
  }
}

@include respond-up('large') {
  .modal {
    &__container {
      &.default {
        --modal-content-padding: 80px calc(var(--grid-column) + var(--grid-gap));
      }

      &._layout-modal {
        --modal-content-padding: 60px 0 0;
      }

      &._floor-modal {
        --modal-content-padding: 60px calc(var(--grid-column) + var(--grid-gap)) 149px;
      }

      &._layout-modal,
      &._floor-modal {
        .modal {
          &__closer {
            top: 60px;
            right: 60px;
          }
        }
      }

      &._popup {
        .modal {
          &__closer {
            top: 30px;
            right: 40px;
          }
        }
      }
    }

    &__content {
      &._floor-modal {
        padding: var(--grid-gap);
        width: calc(100vw + var(--grid-gap) / 2 - 40px);
        height: calc(var(--vh, 1vh) * 100 - 110px);
      }
    }
  }
}

@include respond-up('s-large') {
  .modal {
    &__closer {
      width: 48px;
      height: 48px;
      transition: border-color var(--default-timing) var(--default-bezier);

      &:before,
      &:after {
        width: 24px;
      }

      &:hover {
        border-color: var(--color-grey-02);
      }
    }

    &__container {
      &.default {
        .modal {
          &__closer {
            top: 30px;
            right: 40px;
          }
        }
      }

      &._popup {
        .modal {
          &__content {
            width: var(--grid-column8);
          }
        }
      }
    }
  }
}

@include respond('s-large') {
  .modal {
    &__container {
      &.default {
        --modal-content-padding: 80px 62px;
      }

      &._layout-modal {
        --modal-content-padding: 56px 0 0;
      }

      &._floor-modal {
        --modal-content-padding: 54px calc(var(--grid-column) + var(--grid-gap)) 60px;
      }

      &._layout-modal,
      &._floor-modal {
        .modal {
          &__closer {
            top: 50px;
            right: 50px;
          }
        }
      }

      &._popup {
        .modal {
          &__closer {
            top: 20px;
            right: 20px;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .modal {
    &__layout {
      padding: 80px 0;
    }

    &__container {
      &._popup {
        border-radius: 6px;
        overflow: hidden;
      }
    }
  }
}

@include respond('medium') {
  .modal {
    &__container {
      &.default {
        --modal-content-padding: 62px 40px 70px;

        .modal {
          &__closer {
            top: 20px;
            right: 20px;
          }
        }
      }

      &._layout-modal {
        --modal-content-padding: 40px 0 0;
      }

      &._floor-modal {
        --modal-content-padding: 142px var(--grid-spacer) 85px;
      }

      &._layout-modal,
      &._floor-modal {
        .modal {
          &__closer {
            top: 40px;
            right: 30px;
          }
        }
      }

      &._popup {
        .modal {
          &__content {
            width: var(--grid-column6);
          }

          &__closer {
            top: 30px;
            right: 30px;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .modal {
    &__closer {
      width: 42px;
      height: 42px;

      &:before,
      &:after {
        width: 22px;
      }
    }
  }
}

@include respond-down('small') {
  .modal {
    &__container {
      height: calc(var(--vh, 1vh) * 100);

      &.default {
        --modal-content-padding: 120px var(--grid-spacer);
        width: 100%;

        .modal {
          &__closer {
            top: 40px;
            right: 20px;
          }
        }
      }

      &._layout-modal {
        --modal-content-padding: 30px 0 0;
      }

      &._floor-modal {
        --modal-content-padding: 82px var(--grid-spacer) 34px;
      }

      &._layout-modal,
      &._floor-modal {
        height: max-content;

        .modal {
          &__closer {
            top: 20px;
            right: 20px;
          }
        }
      }

      &._popup {
        width: 100%;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        .modal {
          &__closer {
            top: 30px;
            right: 20px;
          }
        }
      }
    }
  }
}
