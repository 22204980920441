.chess {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &__wrap {
    height: 100%;
  }

  &__floor {
    display: flex;
    align-items: center;
    width: max-content;
  }

  &__floor-number {
    flex: 0 0 21px;
    display: flex;
    justify-content: flex-end;
  }

  &__apartments {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
  }

  &__apartment {
    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  &__apartment-link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  &__apartment-title {
    font-style: normal;
    font-weight: 400;
    color: var(--color-add-04);
  }

  &__apartment-balloon {
    opacity: 0;
    visibility: hidden;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 100;
    background-color: var(--color-add-04);
    padding: 6px 12px 6px 20px;
    border-radius: 6px;
    filter: drop-shadow(0px 1px 25px rgba(58, 68, 73, 0.1));

    // Отступ балуна от точки привязки
    --balloon-offset: 2px;
    // Цвет "Ножки балуна"
    --balloon-pin-color: #fff;

    --pin-y-offset: 4px;

    &.to-right {
      margin-left: var(--balloon-offset);

      &:before {
        left: -5px;
        border-width: 8px 8px 8px 0;
        border-color: transparent var(--balloon-pin-color) transparent transparent;
      }
    }

    &.to-left {
      margin-right: var(--balloon-offset);

      &:before {
        right: -5px;
        border-width: 8px 0 8px 8px;
        border-color: transparent transparent transparent var(--balloon-pin-color);
      }
    }

    &.to-top:before {
      bottom: var(--pin-y-offset);
    }

    &.to-bottom:before {
      top: var(--pin-y-offset);
    }

    &:before {
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
    }

    &._visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__apartment-balloon-price {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    color: var(--color-main-01);
    white-space: nowrap;
  }

  &__apartment-balloon-title {
    margin-top: 4px;
    white-space: nowrap;
  }

  &__apartment-balloon-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    white-space: nowrap;
  }

  &__apartment-picture-wrap {
    width: 93px;
    height: 98px;
    margin-left: 7px;
  }

  &__apartment-picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__aparment-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__back {
    &.c-genplan__interface-back {
      position: relative;
      left: unset;
      top: unset;
    }

    .c-genplan {
      &__back-button-icon {
        background-color: var(--t);
        border: 1px solid var(--color-main-01);
      }

      &__back-button-text {
        color: var(--color-add-05);
      }
    }
  }

  &__info-item {
    display: flex;
    align-items: center;
  }

  &__info-item-text {
    font-style: normal;
    font-weight: 400;
  }

  &__info-item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
  }

  &__table-inner {
    overflow: scroll;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: var(--t);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-grey-02);
    }
  }

  // свободно
  &__vacancy {
    background-color: var(--color-add-07-40);
  }

  // продано
  &__sold {
    background-color: var(--color-grey-02);
  }

  // Забронировано
  &__reserved {
    border: 1px solid var(--color-grey-02);
    //background: url('/templates/_parts/icons/reserved.svg') center center no-repeat;
    background-size: 24px 24px;

    svg {
      path {
        stroke: var(--color-grey-02);
      }
    }
  }
}

@include respond-up('large') {
  .chess {
    &__wrap {
      padding-bottom: 60px;
    }

    &__table {
      height: 820px;
    }

    &__floor {
      &:not(:first-child) {
        margin-top: 17px;
      }
    }

    &__info-list {
      margin-top: 66px;
    }

    &__apartment {
      &:not(:first-child) {
        margin-left: 14px;
      }
    }

    &__apartment-link {
      width: 30px;
      height: 30px;
    }

    &__info-item-icon {
      width: 30px;
      height: 30px;
    }

    &__apartment-title {
      font-size: 14px;
      line-height: 140%;
    }

    &__info {
      padding-top: 60px;
    }
  }
}

@include respond-up('s-large') {
  .chess {
    &__info {
      grid-column: 1/4;
    }

    &__table {
      grid-column: 4/13;
      padding: 52px 0 0 calc(var(--grid-column2) + var(--grid-gap));
    }

    &__apartments {
      margin-left: 30px;
    }

    &__apartment {
      &:hover {
        .chess {
          //&__apartment-balloon {
          //  opacity: 1;
          //  visibility: visible;
          //}

          &__apartment-link.chess__vacancy {
            background-color: var(--color-add-07);
          }
        }
      }
    }

    &__apartment-link {
      transition: background-color var(--default-timing) var(--default-bezier);
    }
  }
}

@include respond('s-large') {
  .chess {
    &__wrap {
      padding-bottom: 40px;
    }

    &__table {
      height: 670px;
    }

    &__info-list {
      margin-top: 52px;
    }

    &__info {
      padding-top: 50px;
    }
  }
}

@include respond-down('s-large') {
  .chess {
    &__apartment-title {
      font-size: 12px;
      line-height: 130%;
    }

    &__floor {
      &:not(:first-child) {
        margin-top: 13px;
      }
    }

    &__apartment-link,
    &__info-item-icon {
      width: 24px;
      height: 24px;
    }
  }
}

@include respond-up('medium') {
  .chess {
    &__info-item-text {
      margin-left: 12px;
      font-size: 17px;
      line-height: 160%;
    }

    &__info-item {
      &:not(:first-child) {
        margin-top: 14px;
      }
    }

    &__table {
      border-left: 1px solid var(--color-grey-02);
    }
  }
}

@include respond('medium') {
  .chess {
    &__wrap {
      padding-bottom: 30px;
    }

    &__info-list {
      margin-top: 40px;
    }

    &__info {
      grid-column: 1/3;
      padding-top: 40px;
    }

    &__table {
      grid-column: 3/7;
      padding: 0 calc(var(--grid-column) + var(--grid-gap)) 0 76px;
      height: 639px;
    }

    &__reserved {
      background-size: 18px 18px;
    }
  }
}

@include respond-down('medium') {
  .chess {
    &__apartments {
      margin-left: 20px;
    }

    &__apartment-balloon {
      display: none;
    }
  }
}

@include respond-down('small') {
  .chess {
    &__wrap {
      grid-template-rows: auto 1fr;
      grid-row-gap: 0;
    }

    &__info {
      grid-column: 1/5;
      padding-top: 20px;
    }

    &__table {
      grid-column: 1/5;
      margin-top: 31px;
      height: 387px;
    }

    &__info-list {
      margin-top: 20px;
      display: flex;
    }

    &__info-item-text {
      margin-left: 8px;
      font-size: 13px;
      line-height: 150%;
    }

    &__info-item {
      &:not(:first-child) {
        margin-left: 15px;
      }
    }

    &__floor {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    &__reserved {
      background-size: 15px 15px;
    }
  }
}