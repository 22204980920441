.advantages-cards {
  &__item {
    position: relative;
    overflow: hidden;

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      mix-blend-mode: multiply;
    }

    &::before {
      background: linear-gradient(180deg, rgba(22, 26, 37, 0) 44.19%, #161A25 100%);
      opacity: 0.7;
    }

    &::after {
      background: #161A25;
      opacity: 0;
      transition: opacity var(--default-timing) var(--default-bezier);
    }

    &:hover {
      &::after {
        opacity: 0.6;
      }

      .advantages-cards {
        &__item-title {
          transition-duration: .6s;
          bottom: 100%;
        }

        &__item-description {
          bottom: 0;
          opacity: 1;
          transition-duration: .6s, .3s;
          transition-delay: 0s, .3s;
        }
      }
    }
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrap {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__item-title {
    color: #fff;
    position: absolute;
    bottom: 0;
    transition: bottom .5s ease-in-out;
  }

  &__item-description {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 160%;
    color: #fff;
    bottom: -140px;
    opacity: 0;
    transition: bottom .5s, opacity .3s;
    transition-timing-function: ease-in-out;
  }
}

@include respond-up('large') {
  .advantages-cards {
    &__item {
      height: calc((430 / 1600) * 100vw);
    }

    &__item-description {
      margin-top: 20px;
    }

    &__text-wrap {
      right: 40px;
      bottom: 40px;
      left: 40px;
    }
  }
}

@include respond-up('s-large') {
  .advantages-cards {
    &__item {
      grid-column: span 4;
      max-height: 430px;

      &:nth-child(7n + 2) {
        grid-column: 5/13;
      }

      &:nth-child(7n + 6),
      &:nth-child(7n) {
        grid-column: span 6;
      }
    }

    &__item-description {
      font-size: 17px;
    }
  }
}

@include respond('s-large') {
  .advantages-cards {
    &__item {
      height: calc((358 / 1280) * 100vw);
    }
  }
}

@include respond-down('s-large') {
  .advantages-cards {
    &__item-description {
      margin-top: 10px;
    }

    &__text-wrap {
      right: 20px;
      bottom: 20px;
      left: 20px;
    }
  }
}

@include respond('medium') {
  .advantages-cards {
    &__item {
      height: calc((312 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .advantages-cards {
    &__list.grid-block {
      grid-template-columns: 1fr;
    }

    &__item {
      grid-column: span 1;
    }

    &__item-description {
      font-size: 16px;
    }
  }
}

@include respond-down('small') {
  .advantages-cards {
    &__item {
      height: calc((400 / 375) * 100vw);
      margin: 0 calc(0px - var(--grid-spacer));
    }
  }
}