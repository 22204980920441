.inline-form {
  &__field-wrap {
    display: flex;
  }

  &__field {
    position: relative;
  }
}

@include respond-up('medium') {
  .inline-form {
    &__field {
      width: var(--grid-column3);

      input {
        width: 100%;
      }

      &:nth-child(2) {
        margin-left: var(--grid-gap);
      }
    }
  }
}

@include respond-down('medium') {
  .inline-form {
    &__field {
      font-size: 15px;
      line-height: 130%;
    }
  }
}

@include respond-down('small') {
  .inline-form {
    &__field-wrap {
      flex-direction: column;
    }

    &__field {
      width: var(--grid-column4);

      input {
        width: 100%;
      }

      &:nth-child(2) {
        margin-top: 16px;
      }
    }

    &__submit {
      width: fit-content;
    }
  }
}