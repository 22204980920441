.advantages-accordion {
  &__item {
    border-bottom: 1px solid var(--color-grey-02);

    &._opened {
      .advantages-accordion {
        &__link {
          &::before {
            transform: translate3d(0, -50%, 0) rotate(45deg);
          }

          &::after {
            transform: translate3d(0, -50%, 0) rotate(135deg);
          }
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate3d(0, -50%, 0);
      height: 2px;
      width: 20px;
      background-color: var(--color-grey-03);
      transition: transform var(--default-timing) var(--default-bezier);
    }

    &::after {
      transform: translate3d(0, -50%, 0) rotate(90deg);
    }
  }

  &__block {
    height: 0;
    overflow: hidden;
    transition: height .7s var(--default-bezier);
  }

  &__block-inner {
    padding-bottom: 30px;
  }

  &__link-title {
    color: var(--color-grey-03);
  }

  &__description {
    color: var(--color-grey-03);
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .advantages-accordion {
    &__link {
      padding: 24px 0;
    }

    &__texts {
      height: 677px;

      &::before {
        height: 641px;
      }
    }

    &__list-wrap {
      max-height: 677px;
    }
  }
}

@include respond-up('s-large') {
  .advantages-accordion {
    border-top: 1px solid var(--color-grey-02);
    border-bottom: 1px solid var(--color-grey-02);
    padding-top: 40px;

    &__texts {
      position: relative;
      grid-column: 1/6;

      &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        background-color: var(--color-grey-02);
      }
    }

    &__list-wrap {
      padding-right: 30px;
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__list {
      padding-right: var(--grid-column);
    }

    &__images-wrap {
      grid-column: 6/13;
      height: calc((562 / 1600) * 100vw);
      max-height: 562px;
    }

    &__images {
      width: 100%;
      height: 100%;
    }

    &__images-item {
      &.swiper-slide {
        width: 100%;
      }

      &.swiper-slide-active {
        .advantages-accordion__picture-wrap {
          width: 100%;
        }
      }
    }

    &__item-image {
      display: none;
    }

    &__picture-wrap {
      position: relative;
      overflow: hidden;
      width: 30%;
      height: calc((562 / 1600) * 100vw);
      max-height: 562px;
      transition: width .7s;
    }

    &__picture {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      width: var(--grid-column7);
      height: calc((562 / 1600) * 100vw);
    }
  }
}

@include respond('s-large') {
  .advantages-accordion {
    &__texts {
      height: 566px;

      &::before {
        height: 527px;
      }
    }

    &__list-wrap {
      max-height: 566px;
    }
  }
}

@include respond-down('s-large') {
  .advantages-accordion {
    &__link {
      padding: 20px 0;
    }
  }
}

@include respond-up('medium') {
  .advantages-accordion {
    &__link {
      padding-right: 30px;
    }
  }
}

@include respond('medium') {
  .advantages-accordion {
    &__block-inner {
      padding-right: calc(var(--grid-column) + var(--grid-gap));
    }

    &__item-image {
      height: calc((388 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .advantages-accordion {
    &__inner {
      &.grid-block {
        grid-template-columns: 1fr;
      }
    }

    &__images-wrap {
      display: none;
    }

    &__item-image {
      margin-top: 20px;
    }

    &__item {
      &:first-child {
        border-top: 1px solid var(--color-grey-02);
      }
    }
  }
}

@include respond-down('small') {
  .advantages-accordion {
    &__link {
      padding-right: 26px;
    }

    &__item-image {
      height: calc((228 / 375) * 100vw);
    }
  }
}