.layout {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100); //true-vh
  --background-footer-color: var(--color-grey-01);

  &__wrapper {
    background-color: var(--color-add-04);
  }
}

// ui variants — конструкция для копирования куда надо
.ui {
  &_one {
  }

  &_two {
  }

  &_three {
  }

  &_four {
  }
}

// верстать стоит все блоки одинакого, и только менять цвета?… наверное.

.ui {
  // табы
  --tabs-list-background: var(--color-grey-01);

  // checkbox switcher
  --infra-checkbox-inactive-border-color: var(--color-grey-02);
  --infra-checkbox-active-border-color: var(--color-main-01);
  --infra-checkbox-inactive-bg: var(--color-grey-02);
  --infra-checkbox-active-bg: var(--color-main-01);
  --infra-checkbox-inactive-switcher-bg: var(--color-add-04);
  --infra-checkbox-active-switcher-bg: var(--color-add-04);

  &_one {
    // кнопки
    --button-background-color: var(--color-main-01);
    --button-background-hover-color: var(--color-main-01-80);
    --button-border-color: var(--color-main-01);
    --button-border-radius: 0;
    --button-text-color: var(--color-add-04);
    // инпуты
    --input-background-color: var(--color-grey-01);
    --input-border-radius: 0;
    --input-border: none;
    // социалки
    --socials-bg: var(--color-main-01-80);
    --socials-hover: var(--color-main-01-80);
    --socials-radius: 0;
    --socials-border: none;
    --socials-item-hover: var(--t);
    --socials-wrap-item-hover: var(--color-grey-03);
    // табы
    --tabs-border-color: var(--t);
    --tabs-active-background: var(--color-main-01); // акцент
    --tabs-active-color: var(--color-add-04); // белый
    --tabs-hover-color: var(--color-add-05); // черный
    // фильтр табы
    --filter-tabs-radius: 0;
    // фильтр range
    --filter-range-bg: var(--color-grey-01);
    --filter-range-border: var(--t);
    --filter-range-radius: 0;
    // фильтр селект
    --filter-select-wrap-bg: var(--color-grey-01);
    --filter-select-border: var(--t);
    --filter-select-radius: 0;
    --filter-select-options-radius: 0;
    // фильтр сортировка
    --filter-sort-margin-left: 6px;
    // checkbox switcher
    --infra-checkbox-border-radius: 0;
  }

  &_two {
    // кнопки
    --button-background-color: var(--color-main-01);
    --button-background-hover-color: var(--color-main-01-80);
    --button-border-color: var(--color-main-01);
    --button-border-radius: 40px;
    --button-text-color: var(--color-add-04);
    // инпуты
    --input-background-color: var(--color-grey-01);
    --input-border: none;
    // социалки
    --socials-bg: var(--color-main-01-80);
    --socials-hover: var(--color-grey-01);
    --socials-radius: 50%;
    --socials-border: 1px solid var(--color-add-05);
    --socials-item-hover: var(--color-main-01);
    --socials-wrap-item-hover: var(--color-main-01-80);
    // табы
    --tabs-border-color: var(--t);
    --tabs-active-background: var(--color-main-01); // акцент
    --tabs-active-color: var(--color-add-04); // белый
    --tabs-hover-color: var(--color-add-05); // черный
    // фильтр табы
    --filter-tabs-radius: 30px;
    // фильтр range
    --filter-range-bg: var(--color-grey-01);
    --filter-range-border: var(--t);
    --filter-range-radius: 40px;
    // фильтр селект
    --filter-select-wrap-bg: var(--color-grey-01);
    --filter-select-border: var(--t);
    --filter-select-radius: 40px;
    --filter-select-options-radius: 0 0 6px 6px;
    // checkbox switcher
    --infra-checkbox-border-radius: 30px;
  }

  &_three {
    // кнопки
    --button-background-color: var(--color-main-01);
    --button-background-hover-color: var(--color-main-01-80);
    --button-border-color: var(--color-main-01);
    --button-border-radius: 6px;
    --button-text-color: var(--color-add-04);
    // инпуты
    --input-background-color: var(--color-grey-01);
    --input-border: none;
    // социалки
    --socials-hover: var(--color-main-01-80);
    --socials-radius: 0;
    --socials-border: none;
    --socials-item-hover: var(--t);
    --socials-wrap-item-hover: var(--color-grey-03);
    // табы
    --tabs-border-color: var(--t);
    --tabs-active-background: var(--color-main-01); // акцент
    --tabs-active-color: var(--color-add-04); // белый
    --tabs-hover-color: var(--color-add-05); // черный
    // фильтр табы
    --filter-tabs-radius: 5px;
    // фильтр range
    --filter-range-bg: var(--color-grey-01);
    --filter-range-border: var(--t);
    --filter-range-radius: 6px;
    // фильтр селект
    --filter-select-wrap-bg: var(--color-grey-01);
    --filter-select-border: var(--t);
    --filter-select-radius: 6px;
    --filter-select-options-radius: 0 0 6px 6px;
    // checkbox switcher
    --infra-checkbox-border-radius: 6px;
  }

  &_four {
    // кнопки
    --button-background-color: var(--t);
    --button-background-hover-color: var(--color-main-01);
    --button-border-color: var(--color-main-01);
    --button-border-radius: 0;
    --button-text-color: var(--color-add-05);
    // инпуты
    --input-background-color: var(--t);
    --input-border-radius: 2px;
    --input-border: 1px solid var(--color-grey-02);
    // социалки
    --socials-bg: var(--color-main-01-80);
    --socials-hover: var(--color-main-01-80);
    --socials-radius: 0;
    --socials-border: none;
    --socials-item-hover: var(--t);
    --socials-wrap-item-hover: var(--color-grey-03);
    // табы
    --tabs-border-color: var(--color-grey-03);
    --tabs-active-border-color: var(--color-main-01);
    --tabs-list-background: var(--color-add-04);
    --tabs-active-background: var(--color-main-01);
    --tabs-active-color: var(--color-add-04);
    --tabs-hover-color: var(--color-add-05); // черный
    // фильтр табы
    --filter-tabs-radius: 2px;
    // фильтр range
    --filter-range-bg: var(--t);
    --filter-range-border: var(--color-grey-02);
    --filter-range-radius: 2px;
    // фильтр селект
    --filter-select-wrap-bg: var(--t);
    --filter-select-border: var(--color-grey-02);
    --filter-select-radius: 2px;
    --filter-select-options-radius: 0 0 2px 2px;
    --filter-select-top: 0;
    // checkbox switcher
    --infra-checkbox-border-radius: 2px;
    --infra-checkbox-inactive-border-color: var(--color-grey-02);
    --infra-checkbox-active-border-color: var(--color-main-01);
    --infra-checkbox-inactive-bg: var(--color-add-04);
    --infra-checkbox-active-bg: var(--color-add-04);
    --infra-checkbox-inactive-switcher-bg: var(--color-grey-02);
    --infra-checkbox-active-switcher-bg: var(--color-main-01);
  }
}

@include respond-up('large') {
  .layout {
    --header-height: 80px;
  }
}

@include respond-up('s-large') {
  :root {
    --button-font-size: 15px;
  }

  .ui {
    &_one {
      // инпуты
      --input-padding: 12px 14px 13px;
      // социалки
      --socials-width-height: 34px;
      --socials-column-gap: 0;
      // фильтр селект
      --filter-select-top: 0;
    }

    &_two {
      // инпуты
      --input-border-radius: 40px;
      --input-padding: 12px 20px 13px;
      // социалки
      --socials-width-height: 35px;
      --socials-column-gap: 5px;
      // фильтр селект
      --filter-select-top: 28px;
      // фильтр сортировка
      --filter-sort-margin-left: 6px
    }

    &_three {
      // инпуты
      --input-border-radius: 6px;
      --input-padding: 12px 14px 13px;
      // социалки
      --socials-bg: var(--color-grey-02);
      --socials-width-height: 30px;
      --socials-column-gap: 0;
      // фильтр селект
      --filter-select-top: 11px;
      // фильтр сортировка
      --filter-sort-margin-left: 6px
    }

    &_four {
      // инпуты
      --input-padding: 12px 14px 13px;
      // социалки
      --socials-width-height: 30px;
      --socials-column-gap: 0;
      // фильтр селект
      --filter-select-top: 0;
      // фильтр сортировка
      --filter-sort-margin-left: 6px
    }
  }
}

@include respond('s-large') {
  .layout {
    --header-height: 76px;
  }
}

@include respond('medium') {
  .layout {
    --header-height: 70px;
  }
}

@include respond-down('medium') {
  :root {
    --button-font-size: 13px;
  }

  .ui {
    &_one {
      // инпуты
      --input-padding: 12px 10px;
      // социалки
      --socials-width-height: 30px;
      --socials-column-gap: 0;
      --socials-first-child-bg: var(--t);
      --socials-first-child-icon: var(--color-add-05);
      // фильтр селект
      --filter-select-top: 0;
    }

    &_two {
      // инпуты
      --input-border-radius: 26px;
      --input-padding: 12px 16px;
      // социалки
      --socials-width-height: 28px;
      --socials-column-gap: 3px;
      --socials-first-child-bg: var(--color-add-05);
      --socials-first-child-icon: var(--color-add-04);
      // фильтр селект
      --filter-select-top: 22px;
      // фильтр сортировка
      --filter-sort-margin-left: 2px
    }

    &_three {
      // инпуты
      --input-border-radius: 4px;
      --input-padding: 12px 10px;
      // социалки
      --socials-bg: var(--color-main-01-80);
      --socials-width-height: 28px;
      --socials-column-gap: 0;
      --socials-first-child-bg: var(--t);
      --socials-first-child-icon: var(--color-add-05);
      // фильтр селект
      --filter-select-top: 20px;
      // фильтр сортировка
      --filter-sort-margin-left: 2px
    }

    &_four {
      // инпуты
      --input-padding: 12px 10px;
      // социалки
      --socials-width-height: 28px;
      --socials-column-gap: 0;
      --socials-first-child-bg: var(--t);
      --socials-first-child-icon: var(--color-add-05);
      // фильтр селект
      --filter-select-top: 0;
      // фильтр сортировка
      --filter-sort-margin-left: 2px
    }
  }
}

@include respond-down('small') {
  .layout {
    --header-height: 50px;
  }
}