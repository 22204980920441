.link-icon {
  position: relative;
  overflow: hidden;
  padding: 5px;
  margin: -5px;
  display: flex;
  width: fit-content;
  font-size: var(--button-font-size);
  color: var(--color-grey-03);

  &_color {
    &::before,
    &::after {
      background: var(--color-main-01);
    }

    .link-icon {
      &__icon,
      &__icon-two {
        svg path {
          stroke: var(--color-main-01);
        }
      }
    }
  }

  &__icon-two,
  &__icon {
    svg {
      height: 100%;
      width: auto;
    }
  }
}

.ui {
  &_one,
  &_two {
    .link-icon {
      &__icon {
        display: none;
      }
    }
  }

  &_three,
  &_four {
    .link-icon {
      &__icon-two {
        display: none;
      }
    }
  }
}

@include respond-up('s-large') {
  .link-icon {
    &__icon-two,
    &__icon {
      margin-right: 7px;
      height: 17px;
      width: auto;
    }

    &_color {
      align-items: flex-end;
      .link-icon {
        &__icon,
        &__icon-two {
          width: 21px;
          height: 21px;
        }
      }
    }
  }

  .ui {
    &_one {
      .link-icon {
        &:before {
          position: absolute;
          content: '';
          height: 1px;
          width: calc(100% - 10px);
          bottom: 3px;
          left: 5px;
          right: 5px;
          background: var(--t);
          transition: background-color var(--default-timing) var(--default-transition-function);
        }

        &:hover {
          &:before {
            background: var(--color-grey-03);
          }
        }

        &_color {
          &:hover {
            &::before {
              background: var(--color-main-01);
            }
          }
        }
      }
    }

    &_two,
    &_three {
      .link-icon {
        &:hover {
          &:before {
            transition: transform 0.3s var(--default-bezier);
            transform: scaleX(0);
          }

          &:after {
            transition: transform 0.3s var(--default-bezier) 0.33s;
            transform: scaleX(1);
          }
        }

        &:before {
          position: absolute;
          content: '';
          height: 1px;
          width: calc(100% - 10px);
          bottom: 4px;
          left: 5px;
          right: 5px;
          background: var(--color-grey-03);
          display: block;
          transform-origin: right;
          transition: transform .2s ease-out;
        }

        &:after {
          position: absolute;
          content: '';
          height: 1px;
          width: calc(100% - 10px);
          bottom: 4px;
          left: 5px;
          right: 5px;
          background: var(--color-grey-03);
          display: block;
          transform: scaleX(0);
          transform-origin: left;
          transition: transform .2s ease-out;
        }

        &_color {
          &::before,
          &::after, {
            background: var(--color-main-01);
          }
        }
      }
    }

    &_four {
      .link-icon {
        &:before {
          position: absolute;
          content: '';
          height: 1px;
          width: calc(100% - 10px);
          bottom: 2px;
          left: 5px;
          right: 5px;
          background: var(--t);
          transition: background-color var(--default-timing) var(--default-transition-function);
        }

        &:hover {
          &:before {
            background: var(--color-grey-03);
          }
        }

        &_color {
          &:hover {
            &::before {
              background: var(--color-main-01);
            }
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .link-icon {
    &__icon-two,
    &__icon {
      margin-right: 6px;
      height: 15px;
      width: auto;
    }

    &_color {
      .link-icon {
        &__icon,
        &__icon-two {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}