.popup-modal {
  display: flex;

  form {
    $inputs: 'input[type="text"], input[type="search"], input[type="password"], input[type="number"]';

    #{$inputs}, textarea, select {
      background-color: var(--color-add-04);

      &::placeholder {
        color: var(--color-grey-03);
      }
    }
  }

  &__wrap {
    display: flex;
    width: 100%;
    background-color: var(--color-grey-01);
  }

  &__info {
    display: flex;
    position: relative;

    &_step-form {
      .popup-modal {
        &__resolve-screen {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__resolve-screen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    opacity: 1;
    background-color: var(--color-grey-01);
    transition: opacity .5s;
    pointer-events: auto;

    &._hide {
      opacity: 0;
      pointer-events: none;
    }
  }
}

@include respond-up('s-large') {
  .popup-modal {
    &__image-wrapper {
      flex: 0 0 calc(var(--grid-column4) + var(--grid-gap));
    }

    &__info {
      flex: 0 0 var(--grid-column4);
    }

    &__picture {
      &_mob {
        display: none;
      }
    }
  }
}

@include respond('s-large') {
  .popup-modal {
    &__image-wrapper {
      min-height: calc((var(--grid-column4) + var(--grid-gap)) * 466/410);
    }
  }
}

@include respond-up('medium') {
  .popup-modal {

  }
}

@include respond('medium') {
  .popup-modal {
    &__image-wrapper {
      min-height: calc(var(--grid-column3) * (398 / 334));
      flex: 0 0 var(--grid-column3);
    }

    &__info {
      min-height: 398px;
      flex: 0 0 calc(var(--grid-column3) + var(--grid-gap));
      padding: 50px 30px 40px var(--grid-gap);
    }
  }
}

@include respond-down('medium') {
  .popup-modal {
    &__picture {
      &_desk {
        display: none;
      }
    }
  }
}

@include respond-down('small') {
  .popup-modal {
    --min-true-vh: max(calc(var(--vh, 1vh) * 100), 720px);
    min-height: var(--min-true-vh);

    &__wrap {
      flex-direction: column-reverse;
    }

    &__image-wrapper {
      height: calc((447 / 375) * 100vw);
    }
  }
}