.layout-apartments-print {
  &__block {
    margin-top: 4mm;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    padding: 1.5mm 0;
  }

  &__floor,
  &__price {
    flex: 0 0 25mm;
  }

  &__price {
    text-align: right;
  }
}