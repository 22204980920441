.hero-slider-info {
  &__items {
    display: flex;
    align-items: center;
  }

  &__video-link-wrap {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__item-title {
    font-style: normal;
    font-weight: 400;
  }

  &__item-value {
    margin-top: 2px;
    font-style: normal;
  }
}

@include respond-up('large') {
  .hero-slider-info {
    &__item-value {
      font-size: 24px;
    }
  }
}

@include respond-up('s-large') {
  .hero-slider-info {
    &__item-title {
      font-size: 17px;
      line-height: 160%;
    }

    &__video-link-wrap {
      grid-column: 12/13;
    }

    &__item-value {
      font-weight: 600;
      line-height: 155%;
    }
  }
}

@include respond('s-large') {
  .hero-slider-info {
    &__item-value {
      font-size: 23px;
    }
  }
}

@include respond-up('medium') {
  .hero-slider-info {

  }
}

@include respond('medium') {
  .hero-slider-info {
    &__address {
      grid-column: 1/3;
    }

    &__developer {
      grid-column: 3/5;
    }

    &__deadline {
      grid-column: 5/7;
    }

    &__video-link-wrap {
      display: none;
    }

    &__item-title {
      font-size: 13px;
    }

    &__item-value {
      margin-top: 4px;
      font-size: 16px;
      line-height: 160%;
    }
  }
}

@include respond-down('medium') {
  .hero-slider-info {
    padding: 20px 0 30px;

    &__item-title {
      line-height: 150%;
    }

    &__item-value {
      font-weight: 400;
    }
  }
}

@include respond-down('small') {
  .hero-slider-info {
    &__address,
    &__deadline {
      grid-column: 1/3;
    }

    &__developer {
      grid-column: 3/5;
    }

    &__video-link-wrap {
      grid-column: 3/5;
    }

    &__item-title {
      font-size: 11px;
    }

    &__item-value {
      font-size: 13px;
      line-height: 150%;
    }
  }
}