.floor {
  &__genplan-wrap {
    position: relative;
    overflow: hidden;
  }

  &__genplan {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--color-add-04);

    &._visible {
      z-index: 1000;
    }

    .genplan {
      margin-top: 0;

      &__list-title {
        font-family: var(--h-font);
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
        margin-top: 4px;
      }

      &__list-subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 155%;
        margin-top: 0;
      }

      &__object-balloon._popover {
        &.to-top:before {
          transform: translate3d(0, -20px, 0);
        }

        &.to-bottom:before {
          transform: translate3d(0, 20px, 0);
        }

        &:before {
          display: block;
        }
      }
    }
  }

  &._single {
    .floor-selector {
      opacity: 0;
      visibility: hidden;
      margin-top: 0;
      height: 0;
    }
  }

  &__swipe-icon-wrap {
    display: none;
  }
}

@include respond-up('large') {
  .floor {
    grid-column-gap: 66px;

    &__genplan-wrap {
      height: 597px;
    }
  }
}

@include respond-up('s-large') {
  .floor {
    display: grid;
    grid-template-columns: calc(var(--grid-column1) + var(--grid-gap)) 1fr;
    grid-row-gap: 53px;
    width: var(--grid-column10);

    &__heading {
      grid-column: 1/3;
    }

    &__selector {
      grid-column: 1/2;
    }

    &__genplan-wrap {
      grid-column: 2/3;

      .genplan {
        &__minimap {
          display: none;
        }

        &__list {
          padding-top: 10px;
        }
      }
    }

    &__heading {
      height: 42px;
    }

    &__heading-slider {
      &.swiper {
        height: 100%;
      }
    }

    &._single {
      grid-column-gap: 0;
      grid-template-columns: calc(var(--grid-column1) + var(--grid-gap)) var(--grid-column8);
    }
  }
}

@include respond('s-large') {
  .floor {
    grid-gap: 46px;

    &__genplan-wrap {
      height: 515px;
    }
  }
}

@include respond-up('medium') {
  .floor {

  }
}

@include respond('medium') {
  .floor {
    width: calc(var(--grid-column6) - (var(--grid-spacer) * 2));

    &__selector {
      margin-top: 76px;
    }

    &__genplan-wrap {
      height: 527px;
    }

    .genplan {
      &__minimap {
        bottom: -30px;
      }
    }
  }
}

@include respond-down('medium') {
  .floor {
    display: flex;
    flex-direction: column;

    &__selector {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__heading {
      display: none;
    }

    &__selector {
      order: 2;
    }

    &__genplan {
      &[data-initialized="true"] {
        .floor {
          &__swipe-icon {
            transform: translate3d(0, 0, 0);
            opacity: 1;

            &::before {
              animation: genplan-swipe-animation 1.5s 1.8s ease-out forwards;
              -webkit-animation: genplan-swipe-animation 1.5s 1.8s ease-out forwards;
            }
          }
        }

        .genplan__layers {
          animation: genplan-layer-animation 1.5s ease-in-out 1.8s forwards;
          -webkit-animation: genplan-layer-animation 1.5s ease-in-out 1.8s forwards;
        }
      }
    }

    &__genplan-wrap {
      order: 1;

      .genplan {
        &__scenes {
          overflow: unset;
        }

        &__minimap {
          left: 0;
          right: 0;
          bottom: -30px;
          height: 4px;
          padding: 0;
          width: 100% !important;
          border: 1px solid var(--color-grey-02);
          border-radius: 0;
        }

        &__minimap-bar {
          border-radius: 0;
        }

        &__minimap-handle {
          background-color: var(--color-grey-02);
        }
      }

      &[data-initialized="true"] {
        .floor__swipe-icon-wrap {
          display: none;
        }

        .genplan__layers {
          animation: unset;
          -webkit-animation: unset;
        }
      }
    }

    &__swipe-icon-wrap {
      display: flex;
      position: absolute;
      z-index: 2000;
      width: 70px;
      height: 70px;
      bottom: 148px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      pointer-events: none;
    }

    &__swipe-icon {
      display: flex;
      transition: opacity, transform;
      transition-duration: .7s, .8s;
      transition-delay: 1s;
      transition-timing-function: ease-out;
      transform: translate3d(0, -50px, 0);
      opacity: 0;

      &::before {
        content: '';
        position: relative;
        width: 70px;
        height: 70px;
        background-image: url("../images/svg/genplan-swipe.svg");
        background-color: var(--color-main-01);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        border-radius: 100%;
      }
    }
  }
}

@include respond-down('small') {
  .floor {
    width: var(--grid-column4);

    &__selector {
      margin-top: 46px;
    }

    &__genplan-wrap {
      height: 466px;
    }

    .genplan {
      &__minimap {
        top: unset;
        bottom: -20px;
      }
    }
  }
}