// Базовые стили, без которых работать ничего не будет
.genplan {
  position: relative;

  &__scenes {
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  &__scene {
    position: relative;

    &._scrollable {
      .genplan__scene-container {
        overflow-y: hidden;
        overflow-x: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .genplan__minimap {
        opacity: 1;
      }
    }
  }

  &__background {
    z-index: 100;
    position: absolute;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    filter: blur(10px);
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  &__layers {
    position: relative;
    z-index: 1000;
    display: inline-block;
  }

  &__layer-image {
    position: relative;
    display: inline-block;

    picture {
      display: inline-block;
    }
  }

  &__layer-substrate,
  &__layer-polygon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    path._clickable {
      cursor: pointer;
    }
  }

  &__layer-interactive {

  }

  &__layer-substrate,
  &__layer-polygon {
    svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__layer-substrate {
    z-index: 1100;
  }

  &__layer-image {
    z-index: 1200;

    picture, img {
      width: 100%;
      height: 100%;
    }
  }

  &__layer-polygon {
    z-index: 1300;
  }

  &__object-container {
    position: absolute;
    z-index: 1400;
    &._hover {
      z-index: 1500;
    }
  }

  &__object-placemark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  &__object-balloon {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    z-index: -1;
    position: absolute;

    &._hover {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
      z-index: auto;
    }
  }

  &__minimap {
    display: block;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  &__minimap-bar {
    display: block;
    position: relative;
    height: 100%;
  }

  &__minimap-handle {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
  }

  ._popover[data-gen-balloon] {
    [data-gen-balloon-close] {
      display: none;
    }
  }

  &__scene-modals {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1000;

    &._opened {
      pointer-events: auto;
    }
  }

  &__scenes {
    position: relative;
  }

  &__scene {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    min-height: 100%;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;

    &._show {
      opacity: 1;
      pointer-events: auto;
      visibility: visible;
    }

    &._hide {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }
}