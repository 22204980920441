.hero_two {
  --hero-height: calc(var(--vh, 1vh) * 100 - var(--header-height));

  .hero {
    &__text-title {
      @extend .h1;
    }
  }

  .hero-slider-info {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-bottom: 1px solid var(--color-grey-01);
      opacity: .4;
    }

    &__logo {
      display: none;
    }

    &__item-title,
    &__item-value {
      color: var(--color-add-04);
    }
  }

  .hero-info {
    &__address,
    &__logo {
      display: none;
    }
  }
}

@include respond-up('large') {
  .hero_two {
    .hero {
      &__text-description {
        margin-top: 6px;
      }

      &__text-link {
        margin-top: 61px;
      }
    }

    .hero-slider-info {
      padding: 30px 0 60px;
    }
  }
}

@include respond-up('s-large') {
  .hero_two {
    .hero {
      &__text-title {
        width: var(--grid-column6);
      }

      &__text-description {
        width: var(--grid-column5);
      }
    }

    .hero-slider-info {
      &__address {
        grid-column: 1/4;
      }

      &__developer {
        grid-column: 4/7;
      }

      &__deadline {
        grid-column: 7/10;
      }
    }

    .hero-info {
      &__video-link-wrap {
        display: none;
      }

      &__description {
        grid-row: 1/2;
      }

      &__logo,
      &__list {
        grid-row: 2/3;
      }
    }
  }
}

@include respond('s-large') {
  .hero_two {
    .hero {
      &__text-description {
        margin-top: 8px;
      }

      &__text-link {
        margin-top: 49px;
      }
    }

    .hero-slider-info {
      padding: 20px 0 50px;
    }
  }
}

@include respond-up('medium') {
  .hero_two {

  }
}

@include respond('medium') {
  .hero_two {
    .hero {
      &__text-title {
        width: var(--grid-column6);
      }

      &__text-description {
        margin-top: 16px;
      }

      &__text-link {
        margin-top: 41px;
      }
    }

    .hero-info {
      &__inner {
        padding-top: 40px;
      }

      &__video-link-wrap {
        grid-column: 6/7;
        grid-row: 1/4;
        justify-content: flex-end;
      }

      &__video-link {
        margin-top: unset;
      }

      &__video-title {
        display: none;
      }
    }
  }
}

@include respond-down('medium') {
  .hero_two {
    .hero {
      &__text-description {
        width: var(--grid-column4);
      }
    }
  }
}

@include respond-down('small') {
  .hero_two {
    .hero {
      &__text-title {
        width: var(--grid-column4);
      }

      &__text-description {
        margin-top: 10px;
      }

      &__text-link {
        margin-top: 31px;
      }

      &__slider-content-inner {
        padding-bottom: 84px;
      }
    }

    .hero-info {
      &__video-link-wrap {
        display: none;
      }
    }
  }
}