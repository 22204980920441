.policy-checkbox {
  position: relative;

  input {
    position: absolute;
    width: 19px;
    height: 19px;

    &:checked + label {
      &:before {
        background-image: url("../images/svg/checkbox-checked.svg");
      }
    }
  }

  label {
    display: block;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--color-grey-03);
    padding-left: 29px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 19px;
      height: 19px;
      background-image: url("../images/svg/checkbox-unchecked.svg");
      transition: background 0.2s;
    }
  }

  &__policy-link {
    color: var(--color-main-01);
    width: max-content;
  }
}

@include respond-up('s-large') {
  .policy-checkbox {
    label {
      font-size: 12px;
    }

    &__policy-link {
      border-bottom: 1px solid var(--t);
      transition: border-bottom-color .3s;

      &:hover {
        border-bottom-color: var(--color-main-01);
      }
    }
  }
}

@include respond-down('medium') {
  .policy-checkbox {
    label {
      font-size: 11px;

      &::before {
        top: -1px;
      }
    }
  }
}

@include respond-down('small') {
  .policy-checkbox {

  }
}

.ui {
  &_one {
    .policy-checkbox {
      input {
        &:checked + label {
          &:before {
            background-image: url("../images/svg/checkbox-checked.svg");
          }
        }
      }

      label {
        &:before {
          background-image: url("../images/svg/checkbox-unchecked.svg");
        }
      }
    }
  }

  &_two,
  &_three,
  &_four {
    .policy-checkbox {
      input {
        &:checked + label {
          &:before {
            background-image: url("../images/svg/checkbox-round-checked.svg");
          }
        }
      }

      label {
        &:before {
          background-image: url("../images/svg/checkbox-round-unchecked.svg");
        }
      }
    }
  }
}