.fav-success {
  background: #fff;
  padding: 20px var(--grid-spacer) 30px;
  transition: transform .4s var(--default-bezier);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10001;
  transform: translateY(100%);
  box-shadow: 0px -6px 25px rgba(0, 0, 0, 0.07);

  &._opened {
    transform: translateY(0);
  }

  &__wrap,
  &__closer-wrap {
    display: flex;
    align-self: center;
  }

  &__wrap {
    justify-content: space-between;
  }

  &__text-wrap {
    align-self: center;
    font-style: normal;
  }

  &__link-wrap {
    padding: 10px;
    margin: -10px;
  }

  &__link-text {
    position: relative;
    display: inline-block;
  }

  &__closer-wrap {
    border-radius: 50%;
    border: 2px solid var(--color-grey-03-40);
  }

  &__closer {
    position: relative;
    width: 100%;
    height: 100%;

    &:before,
    &:after {
      position: absolute;
      content: '';
      background: #7A7A7A;
      left: 50%;
      top: 50%;
      transform-origin: center;
    }

    &:before {
      transform: translate3d(-50%, -50%, 0) rotate(45deg);
    }

    &:after {
      transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    }
  }
}

@include respond-up('large') {
  .fav-success {
    &__text-wrap {
      margin-left: 37px;
    }
  }
}

@include respond-up('s-large') {
  .fav-success {
    display: flex;
    padding-left: calc(var(--grid-spacer-and-indent) + var(--grid-column) + var(--grid-gap));
    padding-right: calc(var(--grid-spacer-and-indent) + var(--grid-column) + var(--grid-gap));

    &__wrap {
      width: 100%;
    }

    &__icon-wrap,
    &__closer-wrap {
      width: 52px;
      height: 52px;
    }

    &__closer {
      &:before,
      &:after {
        width: 28px;
        height: 2px;
      }
    }

    &__text-wrap {
      font-weight: 600;
      font-size: 18px;
      line-height: 155%;
    }
  }
}

@include respond('s-large') {
  .fav-success {
    &__text-wrap {
      margin-left: 46px;
    }
  }
}

@include respond-up('medium') {
  .fav-success {
    &__content-wrap {
      display: flex;
    }

    &__icon-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--color-main-01-40);
    }
  }
}

@include respond('medium') {
  .fav-success {
    &__text-wrap {
      font-size: 16px;
      line-height: 160%;
      margin-left: 20px;
      width: calc(var(--grid-column3) + var(--grid-gap));
    }

    &__content-wrap {
      flex: 0 0 var(--grid-column5);
    }

    &__icon-wrap {
      svg {
        width: 25px;
        height: auto;
      }
    }
  }
}

@include respond-down('medium') {
  .fav-success {
    &__text-wrap {
      font-weight: 400;
    }

    &__icon-wrap,
    &__closer-wrap {
      width: 42px;
      height: 42px;
    }

    &__closer {
      &:before,
      &:after {
        width: 22px;
        height: 2px;
      }
    }
  }
}

@include respond-down('small') {
  .fav-success {
    padding-top: 20px;
    padding-bottom: 20px;

    &__icon-wrap {
      display: none;
    }

    &__text-wrap {
      font-size: 13px;
      line-height: 150%;
      width: calc(var(--grid-column4) - 55px);
    }

    &__closer-wrap {
      margin-left: 13px;
    }
  }
}