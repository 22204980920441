.hero_five {
  --hero-height: calc(var(--vh, 1vh) * 100);

  &.header {
    .link {
      --link-button-color: var(--color-add-04);
    }

    &._sticky {
      .link {
        --link-button-color: var(--color-main-01);
      }
    }
  }

  .hero {
    &__text-title {
      @extend .h1;
    }

    &__slider-info {
      display: none;
    }

    &__images-slider {
      &::before {
        top: 0;
        height: 50%;
        bottom: unset;
        background: linear-gradient(180deg, #3D4850 0%, rgba(61, 72, 80, 0) 100%);
        mix-blend-mode: multiply;
        opacity: 0.7;
      }

      &::after {
        content: '';
        position: absolute;
        z-index: 25;
        left: 0;
        right: 0;
        bottom: 0;
        height: 50%;
        top: unset;
        background: linear-gradient(180deg, rgba(40, 69, 104, 0) 0%, rgba(46, 66, 94, 0.818914) 60.55%, #2F415B 98.94%);
        mix-blend-mode: multiply;
        opacity: 0.8;
      }
    }

    &__pagination,
    &__arrow-left,
    &__arrow-right {
      bottom: 0;
    }
  }

  .hero-info {
    &__title {
      display: none;
    }
  }
}

@include respond-up('large') {
  .hero_five {
    .hero {
      &__slider-content {
        padding-bottom: 79px;
      }

      &__text-description {
        width: var(--grid-column4);
      }
    }
  }
}

@include respond-up('s-large') {
  .hero_five {
    .hero {
      &__text-description {
        margin-top: 20px;
      }

      &__text-title {
        width: var(--grid-column12);
      }
    }

    .hero-info {
      &__inner {
        grid-template-rows: max-content max-content 1fr;
      }

      &__logo,
      &__list {
        grid-row: 3/4;
      }
    }
  }
}

@include respond('s-large') {
  .hero_five {
    .hero {
      &__slider-content {
        padding-bottom: 59px;
      }

      &__text-description {
        width: var(--grid-column5);
      }
    }
  }
}

@include respond-up('medium') {
  .hero_five {
    .hero {

      &__text-link {
        margin-top: 39px;
      }
    }
  }
}

@include respond('medium') {
  .hero_five {
    .hero {
      &__slider-content {
        padding-bottom: 40px;
      }

      &__text-title {
        width: var(--grid-column6);
      }

      &__text-description {
        margin-top: 12px;
        width: var(--grid-column5);
      }
    }

    .hero-info {
      &__video-link-wrap {
        grid-column: 1/6;
      }
    }
  }
}

@include respond-down('small') {
  .hero_five {
    //--hero-height: max(40vh, 251px);

    .hero {
      &__slider-content {
        padding-bottom: 30px;
      }

      &__text-description {
        margin-top: 9px;
      }

      &__text-link {
        margin-top: 31px;
      }

      &__text-title,
      &__text-description {
        width: var(--grid-column4);
      }

      &__slider-content-inner {
        padding-bottom: 84px;
      }
    }
  }
}