.genplan-district {
  .genplan {

  }
}

@include respond-up('large') {
  .genplan-district {
    height: 880px;
  }
}

@include respond('s-large') {
  .genplan-district {
    height: 710px;
  }
}

@include respond-up('medium') {
  .genplan-district {

  }
}

@include respond('medium') {
  .genplan-district {
    height: 680px;
  }
}

@include respond-down('medium') {
  .genplan-district {

  }
}

@include respond-down('small') {
  .genplan-district {
    height: 600px;
  }
}