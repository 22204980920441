.layout-tabs-block {
  display: flex;
  flex-direction: column;

  &__tabs {
    display: flex;
    justify-content: center;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__contents {
    display: flex;
    justify-content: center;
  }

  &__content {
    position: relative;
  }

  &__genplan {
    --preloader-bg-color: var(--color-add-04);
    width: 100%;
    height: 100%;

    .genplan {
      &__minimap {
        display: none;
      }

      &__layer-substrate,
      &__layer-polygon {
        pointer-events: none;

        svg {
          path {
            opacity: .3;
            fill: var(--color-add-08) !important;
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .layout-tabs-block {
    &__contents {
      margin-top: 26px;
    }

    &__content {
      height: calc(var(--grid-column5) * (531 / 583));
    }
  }
}

@include respond-up('s-large') {
  .layout-tabs-block {
    &__content {
      width: 100%;
    }
  }
}

@include respond('s-large') {
  .layout-tabs-block {
    &__contents {
      margin-top: 20px;
    }

    &__content {
      height: calc(var(--grid-column5) * (440 / 483));
    }
  }
}

@include respond-up('medium') {
  .layout-tabs-block {

  }
}

@include respond('medium') {
  .layout-tabs-block {
    &__tabs {
      margin-top: 30px;
    }

    &__content {
      height: calc((412 / 768) * 100vw);
    }
  }
}

@include respond-down('medium') {
  .layout-tabs-block {
    flex-direction: column-reverse;

    &__content {
      width: var(--grid-column4);
    }
  }
}

@include respond-down('small') {
  .layout-tabs-block {
    &__tabs {
      margin-top: 20px;
    }

    &__content {
      height: calc((305 / 375) * 100vw);
    }
  }
}