.range-field {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__slider {
    width: 100%;
    max-width: calc(100% - 2px);
  }

  .slider-handle {
    top: 2px !important;
  }
}

.ui {
  &_two {
    .range-field {
      &__slider {
        max-width: calc(100% - 38px);
      }
    }
  }
}