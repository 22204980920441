.plannings-list {
  &__sort-wrap {
    margin-left: var(--filter-sort-margin-left);
  }

  &__favorites {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--color-main-01-40);
    svg {
      path {
        stroke: var(--color-main-01);
        transition: fill 0.3s;
      }
    }

    &[data-in-fav="true"] {
      opacity: 1;
      svg path {
        fill: var(--color-main-01);
        transition: 0.3s;
      }
    }
  }

  &__item {
    position: relative;
  }

  &__next-wrap {
    display: flex;
    justify-content: center;
  }

  &__layout-price {
    text-transform: lowercase;
  }

  &._filter-top,
  &._filter-side,
  &._filter-side-alt {
    .plannings-list {
      &__next {
        &::before,
        &:after {
          background: var(--color-main-01);
        }
      }

      &__next-icon {
        svg {
          path {
            stroke: var(--color-main-01);
          }
          rect {
            fill: var(--color-main-01);
          }
        }
      }
    }
  }

  &__actions-wrap {
    position: absolute;
    display: flex;
  }
}

@include respond-up('large') {
  .plannings-list {
    &__favorites {
      width: 34px;
      height: 34px;
    }
  }
}

@include respond-up('s-large') {
  .plannings-list {
    &__favorites {
      transition: opacity var(--default-timing) var(--default-bezier);
      opacity: 0;
    }

    &__item {
      &:hover {
        .plannings-list {
          &__favorites {
            opacity: 1;
          }
        }
      }
    }

    &._filter-side,
    &._filter-side-alt {
      .plannings-list {
        &__actions-wrap {
          left: calc(var(--grid-column5) + 65px);
        }
      }
    }

    &._filter-side,
    &._filter-top {
      .plannings-list {
        &__actions-wrap {
          top: 20px;
        }
      }
    }

    &._filter-top {
      .plannings-list {
        &__actions-wrap {
          left: 20px;
          flex-direction: column;
        }
      }
    }

    &._filter-side-alt {
      .plannings-list {
        &__actions-wrap {
          top: 30px;
        }
      }
    }

    &__actions-wrap {
      gap: 8px;
    }
  }
}

@include respond('s-large') {
  .plannings-list {
    &__favorites {
      width: 30px;
      height: 30px;

      svg {
        width: 17px;
        height: auto;
      }
    }
  }
}

@include respond-up('medium') {
  .plannings-list {
    &__top-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__sort-text-wrap {
      display: flex;
      align-items: center;
    }
  }
}

@include respond('medium') {
  .plannings-list {
    &._filter-side,
    &._filter-side-alt {
      .plannings-list {
        &__actions-wrap {
          left: calc(var(--grid-column4) - 15px);
        }
      }
    }

    &._filter-side,
    &._filter-side-alt,
    &._filter-top {
      .plannings-list {
        &__actions-wrap {
          top: 20px;
        }
      }
    }

    &._filter-top {
      .plannings-list {
        &__actions-wrap {
          left: 16px;
          top: 16px;
        }
      }
    }

    &__actions-wrap {
      column-gap: 6px;
    }
  }
}

@include respond-down('medium') {
  .plannings-list {
    &__favorites {
      width: 30px;
      height: 30px;

      svg {
        width: 17px;
        height: auto;
      }
    }
  }
}

@include respond-down('small') {
  .plannings-list {
    &._filter-side,
    &._filter-side-alt {
      .plannings-list {
        &__list {
          display: grid;
          grid-template-columns: repeat(var(--grid-columns-number), var(--grid-column));
          grid-column-gap: var(--grid-gap);
          grid-row-gap: 18px;
        }
        &__item-link {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;
        }

        &__picture {
          display: flex;
          width: 100%;
        }

        &__image{
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &__layout-info-wrap {
          display: flex;
          flex-direction: column;
          row-gap: 4px;
        }

        &__info {
          display: flex;
          flex-direction: row;
        }

        &__layout-price-wrap {
          border-top: 1px solid var(--color-grey-02);
        }

        &__item {
          background: var(--color-add-04);
        }

        &__image-wrap,
        &__layout-about-wrap {
          display: flex;
        }

        &__layout-about-wrap {
          flex-direction: column;
        }

        &__layout-price {
          text-transform: lowercase;
        }
      }
    }

    &._filter-top,
    &._filter-side,
    &._filter-side-alt{
      .plannings-list {
        &__item {
          grid-column: span 4;
        }

        &__picture {
          height: calc((var(--grid-column2) + (var(--grid-gap) * 2)) * 157/190);
        }

        &__info-name {
          flex: 0 0 calc(var(--grid-column2) - 20px);
        }

        &__info-value {
          margin-left: var(--grid-gap);
        }

        &__favorites {
          top: 16px;
          right: 20px;
        }

        &__layout-price-wrap {
          margin-top: 20px;
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          row-gap: 3px;
        }

        &__layout-about-wrap {
          margin-top: 10px;
        }

        &__image-wrap {
          width: calc(var(--grid-column2) + (var(--grid-gap) * 2));
          align-self: center;
        }

        &__item-top-wrap {
          display: flex;
          flex-direction: column;
        }

        &__flats-found {
          display: none;
        }

        &__sort-text-wrap {
          display: flex;
          align-items: center;
        }

        &__top-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &__list-wrapper {
          margin-top: 20px;
        }

        &__layout-info-wrap {
          margin-top: 4px;
        }
      }
    }

    &._filter-top,
    &._filter-side {
      .plannings-list {
        &__item {
          box-shadow: 0 1px 25px rgba(0, 0, 0, 0.07);
        }

        &__item-link {
          padding: 50px 20px 30px;
        }
      }
    }

    &._filter-side-alt {
      .plannings-list {
        &__list {
          grid-row-gap: 0;
        }

        &__item-link {
          padding: 44px 20px 40px;
        }

        &__item {
          &:before,
          &:after {
            content: '';
            position: absolute;
            left: calc(0px - var(--grid-spacer));
            width: 100vw;
            height: 1px;
            background: var(--color-grey-02);
          }

          &:before {
            top: -1px;
          }

          &:after {
            bottom: 0;
          }
        }
      }
    }

    &._filter-side,
    &._filter-side-alt,
    &._filter-top {
      .plannings-list {
        &__actions-wrap {
          top: 16px;
          left: 20px;
        }
      }
    }

    &__actions-wrap {
      flex-direction: column;
      row-gap: 6px;
    }
  }
}