.layout-info-list {
  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-grey-02);
    padding: 7px 0;

    &:first-child {
      border-top: 1px solid var(--color-grey-02);
    }
  }

  &__key {
    color: var(--color-grey-03);
  }

  &__value {
    margin-left: var(--grid-gap);
  }
}

@include respond-up('s-large') {
  .layout-info-list {
    &__key {
      flex: 0 0 calc(var(--grid-column2) - var(--grid-gap));
    }

    &__value {
      flex: 0 1 100%;
    }
  }
}

@include respond-up('medium') {
  .layout-info-list {

  }
}

@include respond('medium') {
  .layout-info-list {
    &__key,
    &__value {
      flex: 0 0 calc(var(--grid-column3) - var(--grid-spacer));
    }
  }
}

@include respond-down('medium') {
  .layout-info-list {

  }
}

@include respond-down('small') {
  .layout-info-list {
    &__key,
    &__value {
      flex: 0 0 var(--grid-column2);
    }
  }
}
