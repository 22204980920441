.modal-form-success {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    text-align: center;
  }

  &__subtitle {
    color: var(--color-grey-03);
    margin-top: 10px;
    text-align: center;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-main-01-40);
    border-radius: 100%;
  }
}

@include respond-up('large') {
  .modal-form-success {
    &__subtitle {
      margin-top: 18px;
    }
  }
}

@include respond-up('s-large') {
  .modal-form-success {
    width: var(--grid-column4);

    &__title {
      margin-top: 30px;
    }

    &__icon {
      width: 52px;
      height: 52px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}

@include respond('medium') {
  .modal-form-success {
    width: calc(var(--grid-column4) - 80px);
  }
}

@include respond-down('medium') {
  .modal-form-success {
    padding-top: 60px;

    &__title {
      margin-top: 20px;
    }

    &__icon {
      width: 42px;
      height: 42px;

      svg {
        width: 26px;
        height: 26px;
      }
    }
  }
}