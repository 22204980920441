.genplan-block {
  &__swipe-icon-wrap {
    display: none;
  }
}

@include respond-up('large') {
  .genplan-block {
    &__main {
      margin-top: 60px;
    }
  }
}

@include respond('s-large') {
  .genplan-block {
    &__main {
      margin-top: 50px;
    }
  }
}

@include respond-up('medium') {
  .genplan-block {

  }
}

@include respond('medium') {
  .genplan-block {
    &__main {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .genplan-block {
    &__title {
      transition: opacity, transform;
      transition-duration: .9s;
      transition-timing-function: ease-out;
    }

    &__main {
      position: relative;
      transition: opacity, transform;
      transition-duration: .85s;
      transition-delay: .15s;
      transition-timing-function: ease-out;
    }

    &__swipe-icon-wrap {
      display: flex;
      position: absolute;
      z-index: 2000;
      width: 70px;
      height: 70px;
      bottom: 148px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      pointer-events: none;
    }

    &__swipe-icon {
      display: flex;
      transition: opacity, transform;
      transition-duration: .7s, .8s;
      transition-delay: 1s;
      transition-timing-function: ease-out;

      &::before {
        content: '';
        position: relative;
        width: 70px;
        height: 70px;
        background-image: url("../images/svg/genplan-swipe.svg");
        background-color: var(--color-main-01);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        border-radius: 100%;
      }
    }

    &.need-animation {
      .genplan-block {
        &__title {
          transform: translate3d(0, -30px, 0);
          opacity: 0;
        }

        &__main {
          transform: translate3d(0, -20px, 0);
          opacity: 0;
        }

        &__swipe-icon {
          transform: translate3d(0, -50px, 0);
          opacity: 0;
        }
      }
    }

    &.animate {
      .genplan-block {
        &__swipe-icon::before {
          animation: genplan-swipe-animation 1.5s ease-out 1.8s forwards;
        }
      }

      .genplan__layers {
        animation: genplan-layer-animation 1.5s ease-in-out 1.8s forwards;
      }
    }
  }
}

@include respond-down('small') {
  .genplan-block {
    &__main {
      margin-top: 15px;
    }
  }
}

@keyframes genplan-swipe-animation {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  20% {
    transform: translate3d(15px, 0, 0);
    opacity: 1;
  }

  40% {
    transform: translate3d(15px, 0, 0);
    opacity: 1;
  }

  60% {
    transform: translate3d(-5px, 0, 0);
    opacity: 1;
  }

  80% {
    transform: translate3d(-5px, 0, 0);
    opacity: 1;
  }

  to {
    transform: translate3d(-25px, 0, 0);
    opacity: 0;
  }
}

@keyframes genplan-layer-animation {
  from {
    transform: translate3d(0, 0, 0);
  }

  40% {
    transform: translate3d(40px, 0, 0);
  }

  80% {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}