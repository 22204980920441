.drop-menu {
  position: absolute;
  z-index: 650;
  background-color: var(--t);
  transition: background-color var(--default-timing) var(--default-bezier);

  width: 100%;
  height: calc(100vh - var(--header-height));

  pointer-events: none;

  &__container {
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: width var(--default-timing) var(--default-bezier);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    width: var(--drop-menu-inner-width);
    height: 100%;
    background-color: var(--color-grey-01);
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__top,
  &__bottom {
    padding-left: calc(var(--grid-indent) + var(--grid-spacer));
  }

  &__top {
    flex: 0 1 100%;
  }

  &__bottom {
    position: relative;
    flex: 0 0 auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-top: 1px solid var(--color-grey-02);
  }

  &__list {
    display: flex;
    flex-direction: column;
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__link-title {
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    color: var(--color-add-05);
  }

  &__logo {
    display: flex;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: var(--color-grey-02);
      }
    }
  }

  &__item-link {
    display: flex;
  }

  &__pattern {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &._opened {
    background-color: rgba(0, 0, 0, .6);
    pointer-events: auto;

    .drop-menu__container {
      width: var(--drop-menu-inner-width);
    }
  }
}

@include respond-up('large') {
  .drop-menu {
    &__top {
      padding-top: 60px;
      padding-right: 73px;
      padding-bottom: 60px;
    }

    &__bottom {
      padding-top: 30px;
      padding-right: 73px;
      padding-bottom: 116px;
    }

    &__logo {
      width: 49px;
      height: 57px;
    }
  }
}

@include respond-up('s-large') {
  .drop-menu {
    --drop-menu-inner-width: calc(var(--grid-column4) + var(--grid-gap) + var(--grid-spacer) + var(--grid-indent));

    &__link-title {
      font-size: 18px;
    }

    &__list {
      margin: -12px 0;

      &:hover {
        .drop-menu__item-link {
          opacity: .4;
        }
      }
    }

    &__item-link {
      padding: clamp(10px, 12 * 100vw / 1600, 20px) 0;
      transition: opacity var(--default-timing) var(--default-bezier);

      &:hover {
        opacity: 1 !important;
      }
    }

    &__choose-apart {
      display: none;
    }
  }
}

@include respond('s-large') {
  .drop-menu {
    &__top {
      padding-top: 40px;
      padding-right: 57px;
      padding-bottom: 40px;
    }

    &__bottom {
      padding-top: 20px;
      padding-right: 57px;
      padding-bottom: 93px;
    }

    &__logo {
      width: 45px;
      height: 52px;
    }
  }
}

@include respond-up('medium') {
  .drop-menu {
    &__link-title {
      margin-left: 20px;
    }
  }
}

@include respond('medium') {
  .drop-menu {
    --drop-menu-inner-width: calc(var(--grid-column5) + var(--grid-spacer));

    &__item-link {
      padding: clamp(10px, 15 * 100vh / 1009, 24px) 0;
    }

    &__top {
      padding-top: clamp(40px, 50 * 100vh / 1009, 70px);
      padding-bottom: 50px;
    }

    &__bottom {
      padding-top: 30px;
      padding-bottom: 90px;
    }

    &__logo {
      width: 49px;
      height: 57px;
    }

    &__choose-apart {
      margin-top: 40px;
    }
  }
}

@include respond-down('medium') {
  .drop-menu {
    &__link-title {
      font-size: 16px;
    }

    &__top,
    &__bottom {
      padding-right: 50px;
    }

    &__list {
      margin: -15px 0;
    }

    &__choose-apart {
      width: var(--grid-column3);
    }
  }
}

@include respond-down('small') {
  .drop-menu {
    --drop-menu-inner-width: 100vw;

    &__item-link {
      padding: clamp(10px, 15 * 100vh / 720, 24px) 0;
    }

    &__top {
      padding-top: clamp(30px, 40 * 100vh / 720, 50px);
      padding-bottom: 40px;
    }

    &__bottom {
      padding-top: 20px;
      padding-bottom: 80px;
    }

    &__link-title {
      margin-left: 14px;
    }

    &__logo {
      width: 43px;
      height: 50px;
    }

    &__choose-apart {
      margin-top: 30px;
    }
  }
}