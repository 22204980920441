.text-wrap {
  &__main-text {
    position: relative;

    &.animate {
      &:before {
        transform: translateY(0);
        opacity: 1;
      }

      .hero {
        &__main-text-inner {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      height: 2px;
      background: black;
      left: 0;
      transition: transform .8s, opacity .8s;
      transition-delay: 0.2s;
      transition-timing-function: var(--teaser-transition-func);
      transform: translateY(62px);
      opacity: 0;
    }
  }

  &__main-text-inner {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    opacity: 0;
    transform: translateY(100%);
    transition: transform .8s, opacity .8s;
    transition-timing-function: var(--teaser-transition-func);

    @for $i from 2 through 100 {
      &:nth-child(#{$i}) {
        transition-delay: calc(0.1s + (#{$i} * 0.1s));
      }
    }

  }

  //media
  &__main-text {
    //grid-column: 1/5;

    &:before {
      width: 54px;
      top: 16px;
    }
  }

  &__main-text-inner {
    &:nth-child(2) {
      span {
        padding-left: 75px;
      }
    }
  }
}