.popup-modal-success {
  display: flex;
  flex-direction: column;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-main-01-40);
    border-radius: 100%;
  }
}

@include respond-up('large') {
  .popup-modal-success {
    padding: 142px 60px 40px 40px;

    &__subtitle {
      margin-top: 12px;
    }
  }
}

@include respond-up('s-large') {
  .popup-modal-success {
    &__title {
      margin-top: 30px;
    }

    &__icon {
      width: 52px;
      height: 52px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}

@include respond('s-large') {
  .popup-modal-success {
    padding: 110px var(--grid-column) 40px 40px;
  }
}

@include respond-down('s-large') {
  .popup-modal-success {
    &__subtitle {
      margin-top: 10px;
    }
  }
}

@include respond('medium') {
  .popup-modal-success {
    padding: 100px var(--grid-column) 40px 40px;
  }
}

@include respond-down('medium') {
  .popup-modal-success {
    &__title {
      margin-top: 20px;
    }

    &__icon {
      width: 42px;
      height: 42px;

      svg {
        width: 26px;
        height: 26px;
      }
    }
  }
}

@include respond-down('small') {
  .popup-modal-success {
    padding: 100px calc(var(--grid-column) + var(--grid-spacer)) 40px 20px;
  }
}