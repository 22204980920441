.slider-arrows {
  border: 1px solid black;
  border-radius: 50%;
  background: white;
  transition: var(--default-transition);
  display: inline-block;
  text-align: center;

  svg {
    path,
    line {
      transition: var(--default-transition);
    }
  }

  &_right {
    svg {
      transform: rotate(180deg);
    }
  }

  &_right-one {
    svg {
      transform: rotate(180deg);
    }
  }

  &_down {
    svg {
      transform: rotate(-90deg);
    }
  }
}

@include respond-up('s-large') {
  .slider-arrows {
    &:hover {
      background: black;
      svg {
        path,
        line {
          stroke: white;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .slider-arrows {
    width: 55px;
    height: 55px;
    padding-top: 18px;

    &_right {
      margin-left: 22px;
    }
  }
}

@include respond-down('medium') {
  .slider-arrows {
    &_down {
      display: none;
    }
  }
}

@include respond-down('small') {
  .slider-arrows {
    width: 43px;
    height: 43px;
    padding-top: 12px;

    &_right {
      margin-left: 18px;
    }
  }
}
