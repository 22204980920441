.sber-action-link {
  z-index: 10;
  display: flex;
  align-items: flex-start;
  position: relative;

  &:first-child {
    z-index: 11;
  }

  &__icon-wrap {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid var(--action-color-bg-50);
    transition-property: background, border-color;
    transition-duration: var(--default-timing);
    transition-timing-function: var(--default-transition-function);
    width: 28px;
    height: 28px;
  }

  &__icon-image {
    width: 18px;
    height: 18px;
  }

  &:hover {
    .sber-action-link {
      &__icon-wrap {
        background: var(--action-color-bg-50);
        border-color: var(--t);
      }

      &__info-block-wrap,
      &__info-block {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &_modal {
    .sber-action-link {
      &__info-block {
        background: #EEEEF0;

        &:before {
          background: #EEEEF0;
        }
      }
    }
  }

  &_layout {
    .sber-action-link {
      &__icon-wrap {
        border-color: var(--layout-action-color-20);
      }
    }

    &:hover {
      .sber-action-link {
        &__icon-wrap {
          background: var(--layout-action-color-20);
        }
      }
    }
  }

  &__info-block-wrap {
    margin-left: 5px;
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 15;
    pointer-events: none;
  }

  &__info-block {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    transition: opacity var(--default-timing) var(--default-transition-function);
    opacity: 0;
    pointer-events: none;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      left: -5px;
      top: 10px;
      width: 10px;
      height: 10px;
      transform-origin: center;
      transform: rotate(45deg);
    }
  }

  &__link {
    margin-top: 2px;
    text-decoration: underline;
    text-decoration-color: var(--color-main-01);
  }
}

@include respond-up('s-large') {
  .sber-action-link {
    &_index {
      .sber-action-link {
        &__info-block {
          background: #fff;

          &:before {
            background: #fff;
          }
        }
      }
    }
  }
}

@include respond-up('medium') {
  .sber-action-link {
    &__info-block {
      width: 157px;
      padding: 5px 10px 10px;
    }
  }
}

@include respond-down('medium') {
  .sber-action-link {
    &_index {
      .sber-action-link {
        &__info-block {
          background: #EEEEF0;

          &:before {
            background: #EEEEF0;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .sber-action-link {
    &__info-block {
      width: 144px;
      padding: 5px 8px 9px;
    }

    &__icon-wrap {
      background: var(--action-color-bg-50);
      border-color: var(--t);
    }

    &_layout {
      .sber-action-link {
        &__icon-wrap {
          background: var(--layout-action-color-20);
          border-color: var(--t);
        }
      }
    }

    &_modal {
      flex-direction: column;
      align-items: flex-end;

      .sber-action-link {
        &__info-block-wrap {
          margin-top: 5px;
          top: 100%;
          right: 0;
          left: unset;
          bottom: unset
        }

        &__info-block {;
          &:before {
            right: 12px;
            top: -5px;
            left: unset;
          }
        }
      }
    }
  }
}